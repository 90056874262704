import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import { DadosMedico } from 'components';
import { usePublicLayout, useUi } from 'hooks';
import { api } from 'services';
import { token as storage } from 'utils';

import { MedicoAuditor } from 'components/Cid10List/Cid10List';

type ResponseProps = {
  dataResposta: string;
  id: string;
  perguntaId: string;
  resposta: string;
};

type Pendencies = {
  idBeneficiario: string;
  nomeBeneficiario: string;
  perguntaId: number;
  liberarAnexo: boolean;
  pergunta: string;
  doencaId: number;
  idDoenca: string;
  titulo: string;
  especificacaoTitulo: string;
  tipo: string;
  tempo?: string;
  local?: string;
  grau?: string;
  medicoAuditor: MedicoAuditor;
  altura: string;
  peso: string;
};

type RespostaType = { resposta: string };

type FormValues = {
  respostas: RespostaType[];
};

const checkIfFilesAreTooBig = (files: FileList | null): boolean => {
  let isValid = true;
  const filesLength = files?.length || 0;

  for (let i = 0; i < filesLength; i += 1) {
    const file = files?.item(i);

    if (file) {
      const size = file.size / 1024 / 1024;
      if (size > 5) {
        isValid = false;
      }
    }
  }

  return isValid;
};

const checkIfFilesAreCorrectType = (files: FileList | null): boolean => {
  let isValid = true;
  const filesLength = files?.length || 0;

  for (let i = 0; i < filesLength; i += 1) {
    const file = files?.item(i);

    if (file) {
      if (!['application/pdf'].includes(file.type)) {
        isValid = false;
      }
    }
  }

  return isValid;
};

const MIN_LENGTH_INPUT_TEXT = 5;
const MAX_LENGTH_INPUT_TEXT = 250;

const schema = yup.object().shape({
  respostas: yup.array().of(
    yup.object().shape({
      resposta: yup
        .string()
        .min(
          MIN_LENGTH_INPUT_TEXT,
          `O tamanho mínimo é de ${MIN_LENGTH_INPUT_TEXT} caracteres`,
        )
        .max(
          MAX_LENGTH_INPUT_TEXT,
          `O tamanho máximo é de ${MAX_LENGTH_INPUT_TEXT} caracteres`,
        )
        .required('Este campo é obrigatório'),
    }),
  ),
});

const Response: React.FC = () => {
  const [pedencies, setPedencies] = useState<Pendencies[]>([]);
  const [inputFiles, setInputFiles] = useState<(FileList | null)[]>([]);
  const [linkIsValid, setLinkIsValid] = useState(false);
  const [medicoAuditor, setMedicoAuditor] = useState<MedicoAuditor | null>(
    null,
  );
  const inputFilesRef = useRef<HTMLInputElement[]>([]);
  const { errorMessage, successMessage, showBackdrop, hideBackdrop } = useUi();
  const { isAuthenticated } = usePublicLayout();
  const { link } = useParams();
  const navigate = useNavigate();
  const { control, formState, handleSubmit, reset } = useForm<FormValues>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      respostas: [],
    },
  });
  const { fields, append } = useFieldArray({ control, name: 'respostas' });

  const fetchPedencies = useCallback(() => {
    const url = `${process.env.REACT_APP_MS_AUDITORIA_URL}/perguntas/listar-perguntas-link/${link}`;
    const headers = { Authorization: `Bearer ${storage.getAccessToken()}` };

    api
      .get(url, { headers })
      .then(({ data }) => {
        if (Array.isArray(data) && data.length === 0) {
          navigate('/link-invalido');
        }
        setPedencies(data);
      })
      .catch(err => {
        if (err.response.status === 404) {
          navigate('/link-invalido');
        }
        if (err.response.status === 500) {
          navigate('/error');
        }
      });
  }, [link, navigate]);

  useEffect(() => {
    if (linkIsValid) {
      hideBackdrop();
    } else {
      showBackdrop();
    }
    return () => hideBackdrop();
  }, [hideBackdrop, linkIsValid, showBackdrop]);

  useEffect(() => {
    if (pedencies.length) {
      hideBackdrop();
    } else {
      showBackdrop();
    }
  }, [hideBackdrop, pedencies, showBackdrop]);

  useEffect(() => {
    const validaLink = (): void => {
      const url = `${process.env.REACT_APP_MS_AUDITORIA_URL}/link/${link}`;
      const headers = { Authorization: `Bearer ${storage.getAccessToken()}` };

      api
        .get(url, { headers })
        .then(({ data }) => {
          if (data.isValid) {
            setLinkIsValid(true);
          } else {
            navigate('/link-invalido');
          }
        })
        .catch(() => {
          navigate('/rejeitada');
        });
    };

    if (isAuthenticated) {
      validaLink();
    }
  }, [link, navigate, isAuthenticated]);

  useEffect(() => {
    if (linkIsValid) {
      fetchPedencies();
    }
  }, [fetchPedencies, linkIsValid]);

  useEffect(() => {
    if (pedencies.length > 0) {
      setMedicoAuditor(pedencies[0].medicoAuditor);
    }
  }, [pedencies]);

  useEffect(() => {
    const pedenciesLength = pedencies.length;
    let fieldsLength = fields.length;

    for (fieldsLength; fieldsLength < pedenciesLength; fieldsLength += 1) {
      append({ resposta: '' });
    }
  }, [append, fields.length, pedencies.length]);

  const handleChangeInputFiles = (
    files: FileList | null,
    index: number,
  ): void => {
    let isValid = true;

    if (!checkIfFilesAreCorrectType(files)) {
      errorMessage('A extensão do arquivo deve ser somente PDF (.pdf)');
      isValid = false;
    }
    if (!checkIfFilesAreTooBig(files)) {
      errorMessage('O tamanho do arquivo excedeu o tamanho máximo de 5MB');
      isValid = false;
    }

    if (isValid) {
      const currentInputFiles = [...inputFiles];
      currentInputFiles[index] = files;

      setInputFiles(currentInputFiles);
      return;
    }

    if (inputFilesRef.current[index]) {
      inputFilesRef.current[index].value = '';
    }
  };

  const clearForm = (): void => {
    reset();
    setInputFiles([]);
    fetchPedencies();

    if (inputFilesRef.current) {
      inputFilesRef.current = [];
    }
  };

  const sendFiles = (resposta: ResponseProps, index: number): void => {
    const url = `${process.env.REACT_APP_MS_AUDITORIA_URL}/documentos`;
    const headers = {
      Authorization: `Bearer ${storage.getAccessToken()}`,
      'Content-Type': 'multipart/form-data',
    };

    const file = inputFiles[index]?.item(0);

    if (file) {
      const formData = new FormData();
      formData.append('resposta_id', resposta.id);
      formData.append('file', file);

      api.post(url, formData, { headers }).catch(() => {
        errorMessage(
          `Erro ao enviar o arquivo ${file.name} para o servidor - Arquivo inválido ou corrompido.`,
        );
      });
    }
  };

  const onSubmit: SubmitHandler<FormValues> = ({ respostas }) => {
    const url = `${process.env.REACT_APP_MS_AUDITORIA_URL}/respostas`;
    const headers = { Authorization: `Bearer ${storage.getAccessToken()}` };

    const promises = respostas.map((item, index) => {
      const { perguntaId, liberarAnexo } = pedencies[index];
      const body = { perguntaId, resposta: item.resposta };

      return api.post(url, body, { headers }).then(({ data }) => {
        if (liberarAnexo) {
          sendFiles(data as ResponseProps, index);
        }
      });
    });

    showBackdrop();

    Promise.all(promises)
      .then(() => {
        successMessage('Resposta(s) enviada(s) com sucesso!');
        navigate('/registrada');
      })
      .catch(err => {
        if (err?.response.status === 400) {
          errorMessage(err.response.data.errorMessage[0].message);
        }
        navigate('/rejeitada');
      })
      .finally(() => {
        hideBackdrop();
      });
  };

  if (!linkIsValid) {
    return null;
  }

  return (
    <Grid container sx={{ '& > :not(style)': { mb: 2 } }}>
      <Grid item md={12}>
        <Box sx={{ marginBottom: 5 }}>
          <Typography variant="h3">
            Pendências solicitadas pelo médico
          </Typography>
          <Typography variant="subtitle1">
            Confira as informações e responda a cada pergunta relacionada a
            doença preenchida do beneficiário em questão.
          </Typography>
        </Box>
        {medicoAuditor && (
          <Paper sx={{ marginBottom: 5, padding: '1rem 2rem 2rem' }}>
            <Box>
              <Typography fontWeight={600}>
                Médico auditor responsável
              </Typography>
              <DadosMedico
                nome={medicoAuditor.nome}
                crm={medicoAuditor.crm}
                especialidade={medicoAuditor.especialidade}
              />
            </Box>
          </Paper>
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          {fields.map((item, index) => {
            const {
              nomeBeneficiario,
              titulo,
              especificacaoTitulo,
              tipo,
              tempo,
              local,
              grau,
              liberarAnexo,
              pergunta,
              altura,
              peso,
            } = pedencies[index];

            return (
              <Paper
                key={item.id}
                sx={{ marginBottom: 5, padding: '1rem 2rem 2rem' }}
              >
                <Box>
                  <Typography>
                    <b>Nome:</b> {nomeBeneficiario}
                  </Typography>
                  {titulo && (
                    <Typography>
                      <b>Categoria:</b> {titulo}
                    </Typography>
                  )}
                  {especificacaoTitulo && (
                    <Typography>
                      <b>Doença:</b> {especificacaoTitulo}
                    </Typography>
                  )}
                  {tipo && (
                    <Typography>
                      <b>Tipo:</b> {tipo}
                    </Typography>
                  )}
                  {tempo && (
                    <Typography>
                      <b>Tempo:</b> {tempo}
                    </Typography>
                  )}
                  {local && (
                    <Typography>
                      <b>Local:</b> {local}
                    </Typography>
                  )}
                  {grau && (
                    <Typography>
                      <b>Grau:</b> {grau}
                    </Typography>
                  )}
                  {altura && (
                    <Typography>
                      <b>Altura:</b> {altura} m
                    </Typography>
                  )}
                  {peso && (
                    <Typography>
                      <b>Peso:</b> {peso} Kg
                    </Typography>
                  )}
                </Box>
                <Grid item xs={12} sx={{ pt: 1 }}>
                  <Controller
                    control={control}
                    name={`respostas.${index}.resposta`}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        multiline
                        variant="standard"
                        label={pergunta}
                        inputProps={{ maxLength: MAX_LENGTH_INPUT_TEXT }}
                        InputLabelProps={{
                          sx: { whiteSpace: 'normal', position: 'relative' },
                        }}
                        error={!!formState.errors?.respostas?.[index]?.resposta}
                        helperText={`${field.value.length}/${MAX_LENGTH_INPUT_TEXT}`}
                      />
                    )}
                  />
                </Grid>

                {liberarAnexo && (
                  <Grid item xs={12} sx={{ pt: 1 }}>
                    <input
                      ref={ref => {
                        if (ref) {
                          inputFilesRef.current[index] = ref;
                        }
                      }}
                      style={{ width: '100%' }}
                      type="file"
                      accept="application/pdf"
                      onChange={e => {
                        handleChangeInputFiles(e.target.files, index);
                      }}
                      // multiple
                    />
                    <Typography variant="caption">
                      Somente extensões PDF são aceitas. Limite de 5MB por
                      arquivo.
                    </Typography>
                  </Grid>
                )}
              </Paper>
            );
          })}
          {fields.length > 0 && (
            <Stack direction="row" spacing={1} sx={{ marginBottom: 5 }}>
              <Button
                variant="outlined"
                type="button"
                disabled={formState.isSubmitting}
                onClick={clearForm}
              >
                Limpar
              </Button>
              <Button
                variant="contained"
                type="submit"
                disabled={!formState.isValid}
              >
                Enviar
              </Button>
            </Stack>
          )}
        </form>
      </Grid>
    </Grid>
  );
};

export default memo(Response);
