import { Box, Grid } from '@mui/material';
import { AnimatedError } from 'components';
import React from 'react';

const NotFound: React.FC = () => (
  <Box>
    <Grid container display="flex" justifyContent="center" alignItems="center">
      <Box sx={{ width: 'auto' }}>
        <AnimatedError>Página não encontrada!</AnimatedError>
      </Box>
    </Grid>
  </Box>
);

export default NotFound;
