import DiseaseRow, {
  DoencaProps,
  DiseaseProps,
  PendenciaProps,
  RespostaProps,
} from './DiseaseRow';

export type { DoencaProps, DiseaseProps, PendenciaProps, RespostaProps };

export default DiseaseRow;
