import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  Box,
  CircularProgress,
  Collapse,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Skeleton,
  Typography,
} from '@mui/material';

import { Close, ExpandMore } from '@mui/icons-material';

import {
  BeneficiarioInfo,
  DeclaracaoSaudeInfo,
  DiseaseList,
  DocsList,
  DocumentoBeneficiarioProps,
  MessageProps,
  SketchProps,
} from 'components';

import { StatusType, FormularioType } from 'context';
import { TitularProps } from 'pages';
import { useAxios } from 'hooks';
import { date as dateConvert } from 'utils';

type ProtocolInfoProps = {
  details: SketchProps;
  statusProtocol?: string;
  titularDetails: TitularProps | null;
  handleClose: () => void;
  protocol: string;
  date: string;
  titularId?: string;
  collapse: boolean;
  collapseAction: () => void;
  declaracaoStatus?: StatusType;
  rowDetails: string[];
  selectedCpf: string;
};

const DECLA_PREVIOUS_DETAILS_KEY = 'decla_previous_details';

const ProtocolInfo: React.FC<ProtocolInfoProps> = ({
  details,
  statusProtocol,
  titularDetails,
  handleClose,
  titularId,
  protocol,
  date,
  collapse,
  collapseAction,
  rowDetails,
  selectedCpf,
}) => {
  const { api } = useAxios();

  const [listMessages, setListMessages] = useState<MessageProps[]>([]);
  const [documentos, setDocumentos] = useState<DocumentoBeneficiarioProps[]>(
    []
  );
  const [fetchingDocumentos, setFetchingDocumentos] = useState<
    'TODO' | 'IN_PROGRESS' | 'DONE'
  >('TODO');

  const previousForm = useMemo(
    () =>
      ((details.formulario &&
        JSON.parse(details.formulario)) as FormularioType) ||
      (JSON.parse(
        sessionStorage.getItem(DECLA_PREVIOUS_DETAILS_KEY) ?? ''
      ) as FormularioType),
    [details]
  );

  const fetchDocumentos = useCallback(
    (path: string): void => {
      const url = `${process.env.REACT_APP_MS_AGENDA_URL}/documento-beneficiario/${path}`;

      setFetchingDocumentos('IN_PROGRESS');

      api
        .get(url)
        .then(({ data }) => {
          if (Array.isArray(data) && data.length > 0) {
            setDocumentos(data);
          }
        })
        .finally(() => {
          setFetchingDocumentos('DONE');
        });
    },
    [api]
  );

  const fetchChatMessages = useCallback((): void => {
    const url = `${process.env.REACT_APP_MS_AUDITORIA_URL}/chat/lista/protocolo/${protocol}`;
    const params = { protocolo: protocol };

    api
      .get(url, {
        params: { ...params },
      })
      .then(({ data }) => {
        setListMessages(data);
      });
  }, [api]);

  const getDocumentos = useCallback(
    (idBeneficiario: string) =>
      documentos.filter(d => d.idBeneficiario === idBeneficiario),
    [documentos]
  );

  const nomeTitular = useMemo(
    () => titularDetails?.nome || '',
    [titularDetails]
  );
  const telefoneTitular = useMemo(
    () => titularDetails?.telefone || '',
    [titularDetails]
  );
  const emailTitular = useMemo(
    () => titularDetails?.email || '',
    [titularDetails]
  );

  const beneficiarios = useMemo(
    () => previousForm?.beneficiarios,
    [previousForm]
  );

  useEffect(() => {
    if (protocol) {
      fetchDocumentos(protocol);
      fetchChatMessages();
    }
  }, [fetchDocumentos, protocol]);

  const PROTOCOLO_STATUS = (protocolStatus?: string): string | undefined => {
    switch (protocolStatus) {
      case 'PENDENTE DE AUDITORIA':
        return 'Pendente de Auditoria';
        break;

      case 'EM AUDITORIA':
        return 'Em Auditoria';
        break;
      case 'PENDENTE DE RESPOSTA':
        return 'Pendente de Resposta';
        break;

      case 'RESPOSTA EM ANALISE':
        return 'Resposta em Análise';
        break;

      case 'PENDENTE DE ACEITE':
        return 'Pendente de Aceite';
        break;

      case 'CONCLUIDO':
        return 'Concluído';
        break;

      case 'RECUSADO':
        return 'Recusado';
        break;

      default:
        break;
    }
    return protocolStatus;
  };

  return (
    <Paper>
      <Box display="flex" flexDirection="column">
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" flexDirection="column" onClick={collapseAction}>
            <DialogTitle>
              Detalhes do Preenchimento - Protocolo Nº {protocol}
              <IconButton onClick={collapseAction} color="primary">
                <ExpandMore />
              </IconButton>
            </DialogTitle>
          </Box>
          <Box display="flex" padding="16px">
            <IconButton color="primary" onClick={handleClose}>
              <Close />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Collapse
        in={collapse}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          maxHeight: 575,
          overflowY: 'scroll',
        }}
      >
        <DialogContent>
          <Box textAlign="center">{!beneficiarios && <CircularProgress />}</Box>
          <DeclaracaoSaudeInfo
            protocolo={protocol}
            dataPreenchimento={date}
            nomeTitular={nomeTitular}
            obsGeral={previousForm.obsGeral}
            tipoPreenchimento={details.tipoPreenchimento}
            telefone={telefoneTitular}
            email={emailTitular}
            status={PROTOCOLO_STATUS(statusProtocol)}
          />

          {beneficiarios?.map(
            ({
              idBeneficiario,
              nome,
              peso,
              altura,
              sexo,
              dataNasc,
              perguntas,
              cpf,
            }) =>
              selectedCpf === cpf && (
                <Paper key={idBeneficiario} sx={{ mt: 2, p: 2 }}>
                  <Box sx={{ mb: 1 }}>
                    <Typography
                      fontWeight={600}
                      fontSize={20}
                      sx={{
                        display: 'table',
                        '&>span': {
                          fontWeight: 400,
                          fontSize: 14,
                          display: 'table-cell',
                          verticalAlign: 'middle',
                          paddingLeft: 1,
                        },
                      }}
                    >
                      {nome}{' '}
                      <span>
                        {idBeneficiario === titularId
                          ? '(TITULAR)'
                          : '(DEPENDENTE)'}
                      </span>
                    </Typography>
                  </Box>
                  <BeneficiarioInfo
                    protocol={protocol}
                    altura={altura}
                    peso={peso}
                    dataNascimento={dataNasc}
                    sexo={sexo}
                    idBeneficiario={idBeneficiario}
                    disabled
                  />
                  <Box sx={{ mt: 2, mb: 5 }}>
                    {fetchingDocumentos === 'IN_PROGRESS' && (
                      <Skeleton
                        variant="text"
                        sx={{ maxWidth: 450, width: '100%' }}
                      />
                    )}
                    {fetchingDocumentos === 'DONE' &&
                      getDocumentos(idBeneficiario).length > 0 && (
                        <DocsList items={getDocumentos(idBeneficiario)} />
                      )}
                  </Box>
                  <DiseaseList
                    protocol={protocol}
                    idBeneficiario={idBeneficiario}
                    diseases={perguntas}
                    onUpdatePendencias={() => console.log('')}
                    isTitular={idBeneficiario === titularId}
                    unviewedRows={rowDetails}
                    disable
                  />
                </Paper>
              )
          )}

          <Box textAlign="center">
            <DialogTitle>Histórico de mensagens do Chat</DialogTitle>
          </Box>
          {listMessages.length !== 0 ? (
            <Box
              border="1px solid black"
              borderRadius={2}
              padding={2}
              width="60%"
              margin="auto"
              height={350}
              sx={{ overflowY: 'scroll' }}
            >
              {listMessages.map(
                ({ medicoAuditor, dataInclusao, mensagem, id }) => (
                  <Box
                    key={id}
                    bgcolor={medicoAuditor.id >= 1 ? '#4fff98' : 'lightgray'}
                    borderRadius={2}
                    padding={0.5}
                    minWidth={150}
                    maxWidth={400}
                    marginLeft={medicoAuditor.id >= 1 ? 'auto' : 0}
                    marginRight={medicoAuditor.id === 0 ? 'auto' : 0}
                    marginBottom={2}
                  >
                    <Typography fontSize={10} marginBottom={0.5}>
                      {medicoAuditor.nome || 'Beneficiário'}
                    </Typography>
                    <Typography paddingBottom={1}>{mensagem}</Typography>
                    <Typography fontSize={8.5} marginTop={0.5} textAlign="end">
                      {dateConvert.getDatetimeFromStringISO(dataInclusao)}
                    </Typography>
                  </Box>
                )
              )}
            </Box>
          ) : (
            <Typography textAlign="center">
              Nenhuma mensagem enviada.
            </Typography>
          )}
        </DialogContent>
      </Collapse>
    </Paper>
  );
};
export default ProtocolInfo;
