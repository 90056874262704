/* eslint-disable */
import axios, { AxiosRequestConfig } from 'axios';

import { token } from 'utils';

type ResponseType = {
  access_token: string;
  refresh_token: string;
};

const api = axios.create();

const fetchRefreshToken = async (error: any) =>
  new Promise((resolve, reject) => {
    const accessToken = token.getAccessToken();
    const refreshToken = token.getRefreshToken();
    const body = { accessToken, refreshToken };

    return api
      .post(
        `${process.env.REACT_APP_MS_AGENDA_URL}/clients-registrations`,
        body,
      )
      .then(async response => {
        token.setAccessToken(response.data.access_token);
        token.setRefreshToken(response.data.refresh_token);
        return resolve(response);
      })
      .catch(() => reject(error));
  });

api.interceptors.response.use(
  response => response,
  async error => {
    const config = error.config as AxiosRequestConfig;
    const accessToken = token.getAccessToken();

    if (error?.response?.status === 401 && accessToken) {
      try {
        const response = (await fetchRefreshToken(error)) as {
          data: ResponseType;
        };
        if (config?.headers) {
          config.headers.Authorization = `Bearer ${response.data.access_token}`;
        }
        return new Promise(resolve => resolve(api(error.config)));
      } catch (err) {
        alert(
          'Falha ao obter autenticação com o servidor! Tente novamente mais tarde.',
        );
      }
    }
    if (error?.response?.status === 403) {
      alert('Forbidden');
    }
    return Promise.reject(error);
  },
);

export default api;
