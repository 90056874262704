import React, { LegacyRef } from 'react';
import { TextField } from '@mui/material';
import InputMask from 'react-input-mask';

type InputProps = {
  onBlur: () => void;
  onChange: (value: unknown) => void;
  inputRef: LegacyRef<InputMask>;
  errorMessage: string | undefined;
};

const CpfInput: React.FC<InputProps> = ({
  onBlur,
  onChange,
  inputRef,
  errorMessage,
}) => (
  <InputMask
    ref={inputRef}
    onBlur={onBlur}
    onChange={onChange}
    mask="9999"
    maskPlaceholder={null}
  >
    {() => (
      <TextField
        variant="standard"
        error={!!errorMessage}
        helperText={errorMessage}
        InputProps={{ sx: { textAlign: 'center', fontSize: 50 } }}
        sx={{
          maxWidth: 200,
          width: '100%',
          '& .MuiInput-input': {
            textAlign: 'center',
          },
        }}
      />
    )}
  </InputMask>
);

export default CpfInput;
