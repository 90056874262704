import { Box } from '@mui/material';

type TabPanelProps = {
  index: number;
  value: number;
};

const TabPanel: React.FC<TabPanelProps> = ({
  children,
  index,
  value,
  ...other
}) => (
  <Box
    component="div"
    role="tabpanel"
    hidden={value !== index}
    id={`tabpanel=${index}`}
    aria-labelledby={`tab-${index}`}
    {...other}
    sx={{ pt: 5 }}
  >
    {value === index && children}
  </Box>
);

export default TabPanel;
