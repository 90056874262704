const getDatetimeFromStringISO = (stringISO: string): string => {
  const d = new Date(stringISO);
  const date = d.toLocaleDateString();
  const hour = d.toLocaleTimeString();

  return `${date} às ${hour}`;
};

const getCurrentMillis = (): number => new Date().getTime();

export default { getDatetimeFromStringISO, getCurrentMillis };
