import { useState } from 'react';
import { AccountCircle } from '@mui/icons-material';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  useTheme as useMuiTheme,
  useMediaQuery,
} from '@mui/material';
import { usePublicLayout, useTheme } from 'hooks';

const TitularHeader: React.FC = () => {
  const theme = useMuiTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    layout: { assets },
  } = useTheme();
  const { isAuthenticated, logout, protocolo } = usePublicLayout();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar style={{ flexDirection: matches ? 'column' : 'row' }}>
          {assets?.logo && (
            <img
              alt="unimed logo"
              src={assets.logo}
              style={{
                height: '70px',
                padding: '15px',
              }}
            />
          )}
          <Hidden smDown>
            <Typography flexGrow={1}>Portal de Auditoria</Typography>
          </Hidden>
          <Typography>{`Protocolo nº ${protocolo}`}</Typography>

          {isAuthenticated && (
            <Box sx={{ pl: 3 }}>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={logout}>Logout</MenuItem>
              </Menu>
            </Box>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default TitularHeader;
