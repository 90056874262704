const CPF_LENGTH = 11;

const formatCpfCnpj = (value: string): string => {
  const cpfCnpj = value.replace(/\D/g, '');

  if (cpfCnpj.length === CPF_LENGTH) {
    return cpfCnpj.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
  }

  return cpfCnpj.replace(
    /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
    '$1.$2.$3/$4-$5',
  );
};

const formatTelefone = (value: string): string => {
  const telefone = value.replace(/[^\w\s]/gi, '');

  if (telefone.length === 8) {
    return telefone.replace(/(\d{4})(\d{4})/g, '$1-$2');
  }
  if (telefone.length === 9) {
    return telefone.replace(/(\d{5})(\d{4})/g, '$1-$2');
  }
  if (telefone.length === 10) {
    return telefone.replace(/(\d{2})(\d{4})(\d{4})/g, '($1) $2-$3');
  }
  if (telefone.length === 11) {
    return telefone.replace(/(\d{2})(\d{5})(\d{4})/g, '($1) $2-$3');
  }
  if (telefone.length === 12) {
    return telefone.replace(/(\d{3})(\d{5})(\d{4})/g, '($1) $2-$3');
  }

  return telefone;
};

export default { formatCpfCnpj, formatTelefone };
