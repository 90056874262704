import { BrowserRouter as Router } from 'react-router-dom';

import { RoutesConfig } from 'routes';
import { ThemeProvider, UiProvider } from 'context';

import './App.css';

const App: React.FC = () => (
  <ThemeProvider>
    <UiProvider>
      <Router>
        <RoutesConfig />
      </Router>
    </UiProvider>
  </ThemeProvider>
);

export default App;
