import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useMemo,
  useState,
} from 'react';

import { GlobalStyles } from '@mui/material';
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles';
import { ptBR } from '@mui/material/locale';

type TypographyType = {
  fontFamily: string;
};

type ColorType = {
  main: string;
  default?: string;
};

type BackgroundColorType = {
  default: string;
};

type PaletteType = {
  primary: ColorType;
  secondary: ColorType;
  background: BackgroundColorType;
};

type AssetsType = {
  logo: string;
};

type LayoutType = {
  typography: TypographyType;
  palette: PaletteType;
  assets?: AssetsType;
};

export type Theme = {
  layout: LayoutType;
  setLayout: Dispatch<SetStateAction<LayoutType>>;
  layoutIsEmpty: boolean;
};

const ThemeContext = createContext<Theme>({} as Theme);

const DEFAULT_THEME: PaletteType = {
  primary: { main: '#00995D' },
  secondary: { main: '#1C5253' },
  background: { default: '#F9F9FF' },
};

const DEFAULT_THEME_TYPOGRAPHY: TypographyType = {
  fontFamily: ['Roboto', 'sans-serif'].join(','),
};

const typography = { ...DEFAULT_THEME_TYPOGRAPHY };

const ThemeProvider: React.FC = ({ children }) => {
  const [palette] = useState<PaletteType>(DEFAULT_THEME);
  const [layout, setLayout] = useState<LayoutType>({} as LayoutType);

  const layoutIsEmpty = useMemo(
    () => Object.keys(layout).length === 0,
    [layout],
  );

  const theme = useMemo(
    () => createTheme({ typography, palette: layout.palette ?? palette }, ptBR),
    [layout, palette],
  );

  return (
    <ThemeContext.Provider
      value={{
        layout,
        setLayout,
        layoutIsEmpty,
      }}
    >
      <GlobalStyles styles={{ backgroundColor: '#F9F9FF' }} />

      <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
    </ThemeContext.Provider>
  );
};

export { ThemeContext, ThemeProvider };
