import { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import {
  Box,
  Button,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { Close, Send, VisibilityOff } from '@mui/icons-material';

import { useUi } from 'hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosInstance } from 'axios';

import { date } from 'utils';

export type MessageProps = {
  dataInclusao: string;
  dataVisualizacao: string;
  id: number;
  liberarAnexo: false;
  link: {
    id: number;
  };
  medicoAuditor: {
    id: number;
    nome: string;
  };
  mensagem: string;
  protocolo: {
    id: number;
  };
  tipoMensagem: {
    id: number;
  };
};

type ModalChatProps = {
  close: () => void | undefined;
  updatePendencies?: () => void;
  handleProtocol?: string | null;
  title: string;
  maxWidth: number;
  hideCloseButton: boolean;
  issuerMessage: string;
  controllerColorMessage: string;
  clientColorMessage: string;
  issuerAlign: number | 'auto';
  receiverAlign: number | 'auto';
  api: AxiosInstance;
};

type FormValues = {
  mensagem: string;
  protocolo: string;
};

const MAX_LENGTH_INPUT_TEXT = 300;

const schema = yup.object().shape({
  mensagem: yup
    .string()
    .max(
      MAX_LENGTH_INPUT_TEXT,
      `O tamanho máximo é de ${MAX_LENGTH_INPUT_TEXT} caracteres`
    )
    .required(),
});

const ModalChat: React.FC<ModalChatProps> = ({
  api,
  clientColorMessage,
  close,
  controllerColorMessage,
  handleProtocol,
  hideCloseButton,
  issuerAlign,
  issuerMessage,
  maxWidth,
  receiverAlign,
  title,
  updatePendencies,
}) => {
  const { errorMessage, successMessage } = useUi();
  const [listMessages, setListMessages] = useState<MessageProps[]>([]);
  const [disableAction, setDisableAction] = useState(false);
  const { control, formState, handleSubmit, reset } = useForm<FormValues>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      mensagem: '',
      protocolo: handleProtocol || '',
    },
  });

  const LastMessage = listMessages[listMessages.length - 1];

  const hideButton = (enabled: boolean): string => {
    if (enabled === true) {
      return 'none';
    }
    if (enabled === false) {
      return '';
    }
    return '';
  };

  const checkVisualizationMessage = useCallback(
    (idMensagem: number, protocolo: string | null | undefined) => {
      const url = `${process.env.REACT_APP_MS_AUDITORIA_URL}/chat/visualiza`;
      const params = { idMensagem, protocolo };

      api.post(url, params);
    },
    [api]
  );

  const deleteVisualizationMessage = useCallback(
    (idMensagem: number, protocolo: string | null | undefined) => {
      const url = `${process.env.REACT_APP_MS_AUDITORIA_URL}/chat/nao-lida/${protocolo}/${idMensagem}`;

      api.delete(url);
    },
    [api]
  );

  const fetchChatMessages = useCallback(
    (visualization?: boolean): void => {
      const url = `${process.env?.REACT_APP_MS_AUDITORIA_URL}/chat/lista/protocolo/${handleProtocol}`;
      const params = { protocolo: handleProtocol };

      api
        .get(url, {
          params: { ...params },
        })
        .then(({ data }) => {
          const lastMessage = data[data.length - 1];
          const messageResponsible = lastMessage?.medicoAuditor.id;

          if (visualization) {
            if (
              messageResponsible >= 1 &&
              issuerMessage === 'beneficiario' &&
              !lastMessage.dataVisualizacao
            ) {
              checkVisualizationMessage(lastMessage.id, handleProtocol);
            }
            if (
              messageResponsible === 0 &&
              issuerMessage === 'auditor' &&
              !lastMessage.dataVisualizacao
            ) {
              checkVisualizationMessage(lastMessage.id, handleProtocol);
            }
          }
          if (visualization === false) {
            if (!lastMessage.dataVisualizacao) {
              errorMessage('Última mensagem já não possui visualização.');
            }
            if (
              messageResponsible === 0 &&
              issuerMessage === 'auditor' &&
              lastMessage.dataVisualizacao
            ) {
              deleteVisualizationMessage(lastMessage.id, handleProtocol);
              successMessage('Marcado como não lida.');
              close();
              if (updatePendencies) {
                updatePendencies();
              }
            }
          }
          setListMessages(data);
        });
    },
    [api, handleProtocol, issuerMessage, successMessage]
  );

  const notificationMessage = useCallback((): void => {
    const url = `${process.env.REACT_APP_MS_AUDITORIA_URL}/notificacao/enviar-chat/${handleProtocol}`;

    api
      .post(url)
      .then(() => {
        successMessage('Notficação encaminhada com sucesso.');
      })
      .catch(() => {
        errorMessage('Não foi possível enviar sua notificação.');
      });
  }, [api, errorMessage, handleProtocol, successMessage]);

  const timeoutAction = (): void => {
    if (disableAction === false) {
      notificationMessage();
    }
    setDisableAction(true);

    setTimeout(() => {
      setDisableAction(false);
    }, 180000);
  };

  const removeVisualization = (): void => {
    fetchChatMessages(false);
    close();
  };

  const sendMessage = (data: FormValues): void => {
    api
      .post(
        `${process.env.REACT_APP_MS_AUDITORIA_URL}/chat/${issuerMessage}`,
        data
      )
      .then(() => {
        successMessage('A sua mensagem foi enviada com sucesso.');
        reset();
        fetchChatMessages();
        if (issuerMessage === 'auditor') {
          timeoutAction();
        }
      })
      .catch(error => {
        if (!error.response) {
          errorMessage('Não foi possível enviar sua mensagem.');
        }
        if (error.response.status === 400) {
          const { message } = error.response.data.errorMessage[0];
          errorMessage(message);
        }
      });
  };

  const onSubmit: SubmitHandler<FormValues> = data => {
    sendMessage(data);
  };

  const scrollRef: any = useRef(null);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behaviour: 'smooth' });
    }
  }, [listMessages]);

  useEffect(() => {
    fetchChatMessages(true);
  }, []);

  return (
    <Paper sx={{ p: 2, border: '2px solid #33995d', borderRadius: 4 }}>
      <Box
        display="flex"
        justifyContent="space-between"
        height={30}
        marginBottom={2}
      >
        <Typography variant="h6" noWrap marginRight={2}>
          {title}
        </Typography>
        <Box>
          <Button
            variant="contained"
            onClick={close}
            sx={{
              display: hideButton(hideCloseButton),
              minWidth: 4,
              height: 25,
              padding: 0.3,
            }}
          >
            <Close />
          </Button>
        </Box>
      </Box>
      <Box
        height={280}
        display="flex"
        flexDirection="column"
        sx={{
          overflowX: 'hidden',
          overflowY: 'scroll',
          overflowAnchor: 'inherit',
        }}
      >
        {listMessages.map(({ medicoAuditor, dataInclusao, mensagem, id }) => (
          <Box
            key={id}
            borderRadius={2}
            bgcolor={
              medicoAuditor.id >= 1
                ? controllerColorMessage
                : clientColorMessage
            }
            padding={0.5}
            minWidth={150}
            maxWidth={maxWidth}
            marginLeft={medicoAuditor.id >= 1 ? issuerAlign : receiverAlign}
            marginRight={medicoAuditor.id === 0 ? issuerAlign : receiverAlign}
            marginBottom={2}
            ref={scrollRef}
          >
            <Typography fontSize={10} marginBottom={0.5}>
              {medicoAuditor.nome || 'Beneficiário'}
            </Typography>
            <Typography paddingBottom={1}>{mensagem}</Typography>
            <Typography fontSize={8.5} marginTop={0.5} textAlign="end">
              {date.getDatetimeFromStringISO(dataInclusao)}
            </Typography>
          </Box>
        ))}
        {LastMessage?.medicoAuditor?.id === 0 &&
          issuerMessage !== 'beneficiario' && (
            <Box>
              <Tooltip title="Marcar como não visualizada">
                <IconButton
                  onClick={removeVisualization}
                  sx={{
                    display: hideButton(hideCloseButton),
                    minWidth: 3,
                    height: 10,
                    '&:hover': {
                      color: '#00995D',
                    },
                    marginRight: 'auto',
                  }}
                >
                  <VisibilityOff fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        {LastMessage?.dataVisualizacao &&
          LastMessage?.medicoAuditor.id >= 1 &&
          issuerMessage !== 'beneficiario' && (
            <Typography
              fontSize={10}
              alignSelf="end"
              paddingTop={0}
              fontStyle="italic"
            >
              Visualizada em{' '}
              {date.getDatetimeFromStringISO(LastMessage.dataVisualizacao)}
            </Typography>
          )}
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box display="flex" alignItems="center" marginTop={2}>
          <Controller
            control={control}
            name="mensagem"
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                rows={4}
                inputProps={{ maxLength: MAX_LENGTH_INPUT_TEXT }}
                variant="outlined"
                label="Descreva sua Mensagem"
                error={!!formState.errors.mensagem}
                helperText={`${field.value.length}/${MAX_LENGTH_INPUT_TEXT}`}
              />
            )}
          />
          <Button onClick={handleSubmit(onSubmit)}>
            <Send />
          </Button>
        </Box>
      </form>
    </Paper>
  );
};

export default ModalChat;
