import { Box, Grid } from '@mui/material';
import { AnimatedError } from 'components';

const InvalidLink: React.FC = () => (
  <Box>
    <Grid container display="flex" justifyContent="center" alignItems="center">
      <Box>
        <AnimatedError>Este link não é mais válido!</AnimatedError>
      </Box>
    </Grid>
  </Box>
);

export default InvalidLink;
