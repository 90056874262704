import { useState } from 'react';
import { AccountCircle, Menu as MenuIcon } from '@mui/icons-material';
import {
  AppBar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';
import { useMenu, usePrivateLayout } from 'hooks';

const Header: React.FC = () => {
  const { isAuthorized } = usePrivateLayout();
  const { handleOpenMenu } = useMenu();
  const { keycloak } = useKeycloak();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ backgroundColor: '#00995d' }}>
        <Toolbar>
          {isAuthorized && (
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={handleOpenMenu}
            >
              <MenuIcon />
            </IconButton>
          )}

          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, ml: !isAuthorized ? '50px' : 0 }}
          >
            Auditoria
          </Typography>

          {keycloak.authenticated && (
            <Box display="flex" alignItems="center">
              <Typography fontSize={18} mr={2}>
                {keycloak.idTokenParsed?.name ||
                  keycloak.idTokenParsed?.preferred_username}
              </Typography>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={() => keycloak.logout()}>Logout</MenuItem>
              </Menu>
            </Box>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Header;
