import Keycloak, { KeycloakConfig } from 'keycloak-js';

const config: KeycloakConfig = {
  url: process.env.REACT_APP_KEYCLOAK_URL as string,
  realm: process.env.REACT_APP_KEYCLOAK_REALM as string,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID as string,
};

const keycloak = Keycloak(config);

export default keycloak;

export const eventLogger = (event: unknown, error: unknown): void => {
  console.log('onKeycloakEvent', event, error);
};

export const tokenLogger = (tokens: unknown): void => {
  console.log('onKeycloakTokens', tokens);
};
