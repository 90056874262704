import { Badge as BadgeMui, BadgeProps, styled } from '@mui/material';

const StyledBadge = styled(BadgeMui)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: -3,
    background: theme.palette.primary.main,
  },
}));

const BadgeTheme: React.FC<BadgeProps> = ({ children, ...other }) => (
  <StyledBadge {...other}>{children}</StyledBadge>
);

export default BadgeTheme;
