import { Routes, Route } from 'react-router-dom';

import { PrivateLayout, PublicLayout } from 'components';
import {
  Aceite,
  Chat,
  DeclaracaoSaude,
  DeclaracaoSaudeDetails,
  Forbidden,
  Home,
  InvalidLink,
  NotFound,
  Resposta,
  RespostaRegistrada,
  RespostaRejeitada,
  UnexpectedError,
} from 'pages';

const RoutesConfig: React.FC = () => (
  <Routes>
    <Route element={<PrivateLayout />}>
      <Route path="*" element={<NotFound />} />
      <Route path="/" element={<Home />} />
      <Route path="/declaracao-de-saude">
        <Route index element={<DeclaracaoSaude />} />
        <Route path=":protocolo" element={<DeclaracaoSaudeDetails />} />
      </Route>
      <Route path="/error" element={<UnexpectedError />} />
      <Route path="/naoautorizado" element={<Forbidden />} />
    </Route>

    <Route element={<PublicLayout />}>
      <Route path="/aceite">
        <Route path=":link" element={<Aceite />} />
      </Route>
      <Route path="/chat">
        <Route path=":link" element={<Chat />} />
      </Route>
      <Route path="/error" element={<UnexpectedError />} />
      <Route path="/link-invalido" element={<InvalidLink />} />
      <Route path="/registrada" element={<RespostaRegistrada />} />
      <Route path="/rejeitada" element={<RespostaRejeitada />} />
      <Route path="/resposta">
        <Route path=":link" element={<Resposta />} />
      </Route>
    </Route>
  </Routes>
);

export default RoutesConfig;
