import { Box, Typography } from '@mui/material';

type DadosMedicoProps = {
  nome: string;
  crm: string;
  especialidade: string;
};

const DadosMedico: React.FC<DadosMedicoProps> = ({
  nome,
  crm,
  especialidade,
}) => (
  <Box>
    <Typography>
      <b>Nome:</b> {`${nome} - CRM ${crm}`}
    </Typography>
    <Typography>
      <b>Especialidade:</b> {especialidade}
    </Typography>
  </Box>
);

export default DadosMedico;
