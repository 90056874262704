import {
  Box,
  List,
  ListItemButton,
  ListItemText,
  Modal,
  Typography,
} from '@mui/material';
import ProtocolInfo from 'components/ProtocolInfo';
import { useAxios, useUi } from 'hooks';

import { TitularProps } from 'pages';
import { StatusType } from 'context';

import { useCallback, useEffect, useState } from 'react';
import { files } from 'utils';

export type PastProtocolListProps = {
  protocolo: string;
  dataPreenchimento: string;
};

export type SketchProps = {
  protocolo: string;
  formulario: string;
  tipoPreenchimento: string;
};

type StatusProps = {
  id: number;
  nome: string;
};

const DECLA_PREVIOUS_DETAILS_KEY = 'decla_previous_details';

const PastProtocolListList: React.FC<{
  items: PastProtocolListProps[];
  declaracaoStatus?: StatusType;
  rowDetails: string[];
  idOperadora?: number;
  selectedCpf: string;
}> = ({ items, declaracaoStatus, rowDetails, idOperadora, selectedCpf }) => {
  const [loading, setLoading] = useState(false);
  const [collapseState, setCollapseState] = useState(true);
  const [openProtocolInfo, setOpenProtocolInfo] = useState(false);
  const [titular, setTitular] = useState<TitularProps | null>(null);
  const [status, setStatus] = useState<StatusProps>({} as StatusProps);
  const [selectedProtocol, setSelectedProtocol] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [sketch, setSketch] = useState<SketchProps>({} as SketchProps);

  const { api } = useAxios();
  const { errorMessage, showBackdrop, hideBackdrop } = useUi();

  const handleCloseProtocolInfo = (): void => {
    setOpenProtocolInfo(false);
  };

  const collapseCondition = (): void => {
    if (collapseState) {
      setCollapseState(false);
    } else {
      setCollapseState(true);
    }
  };

  const fetchStatus = useCallback(
    (protocolo: string) => {
      const url = `${process.env.REACT_APP_MS_AUDITORIA_URL}/protocolo/${protocolo}`;

      api.get(url).then(({ data }) => {
        setStatus(data.statusProtocolo);
      });
    },
    [api]
  );

  const fetchTitular = useCallback(
    (protocolo: string) => {
      const url = `${process.env.REACT_APP_MS_AGENDA_URL}/preenchimento-formulario/${protocolo}`;

      api
        .get(url)
        .then(({ data }) => {
          fetchStatus(protocolo);
          setTitular(data);
        })
        .catch(() => {
          errorMessage('Não foi possível carregar os dados do titular!');
        });
    },
    [api, errorMessage, fetchStatus]
  );

  const fetchPreviousProtocol = (protocolo: string): void => {
    const urlSketch = `${process.env.REACT_APP_MS_AGENDA_URL}/formulario/operadora/${idOperadora}/protocolo/${protocolo}`;

    setLoading(true);

    api
      .get(urlSketch)
      .then(({ data }) => {
        setSketch(data);
        fetchTitular(protocolo);
        setOpenProtocolInfo(true);
        sessionStorage.setItem(
          DECLA_PREVIOUS_DETAILS_KEY,
          JSON.stringify(data.formulario)
        );
      })
      .catch(() => {
        errorMessage(
          'Não foi possivel carregar dados de preenchimento deste protocolo.'
        );
      })
      .finally(() => setLoading(false));
  };

  const fetchAsset = (protocolo: string, dataPreenchimento: string): void => {
    const urlPdf = `${process.env.REACT_APP_MS_AGENDA_URL}/pdf-declaracao-saude/completo/${protocolo}`;

    setSelectedProtocol(protocolo);
    setSelectedDate(dataPreenchimento);
    setLoading(true);

    api
      .get(urlPdf)
      .then(({ data }) => {
        files.openPdfFromBase64(data.pdf);
      })
      .catch(err => {
        if (err.response.status === 404) {
          fetchPreviousProtocol(protocolo);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (loading) {
      showBackdrop();
    } else {
      hideBackdrop();
    }

    return () => {
      hideBackdrop();
    };
  }, [hideBackdrop, loading, showBackdrop]);

  return items.length === 0 ? null : (
    <Box>
      <Typography fontWeight={600}>
        Este beneficiário está presente nas seguintes declarações de saúde:
      </Typography>

      <List>
        {items.map(({ protocolo, dataPreenchimento }) => (
          <ListItemButton
            key={protocolo}
            onClick={() => fetchAsset(protocolo, dataPreenchimento)}
          >
            <ListItemText
              primary={`Protocolo ${protocolo} preenchido em ${new Date(
                dataPreenchimento
              ).toLocaleDateString()}`}
            />
          </ListItemButton>
        ))}
      </List>

      <Modal
        hideBackdrop={!collapseState}
        disableScrollLock={collapseState}
        disableEscapeKeyDown
        open={openProtocolInfo}
        onClose={handleCloseProtocolInfo}
        sx={{
          mt: 2,
          p: 2,
          height: !collapseState ? 70 : '',
        }}
      >
        <ProtocolInfo
          details={sketch}
          titularDetails={titular}
          statusProtocol={status?.nome}
          protocol={selectedProtocol}
          date={selectedDate}
          handleClose={handleCloseProtocolInfo}
          titularId={titular?.idBeneficiario}
          collapse={collapseState}
          collapseAction={collapseCondition}
          declaracaoStatus={declaracaoStatus}
          rowDetails={rowDetails}
          selectedCpf={selectedCpf}
        />
      </Modal>
    </Box>
  );
};

export default PastProtocolListList;
