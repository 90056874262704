import { Box, Chip, Typography } from '@mui/material';
import { PhotoCamera, PictureAsPdf } from '@mui/icons-material';

import { files } from 'utils';

export type DocumentoBeneficiarioProps = {
  dataInclusao: string;
  documento: string;
  id: number;
  idBeneficiario: string;
  nome: string;
  protocolo: string;
  sessao: string;
  tamanho: number;
  extensao: 'pdf' | 'jpg' | 'jpeg' | 'png';
};

type DocsListProps = {
  items: DocumentoBeneficiarioProps[];
};

const getIconByExtension = (extension: string): JSX.Element =>
  extension.toUpperCase() === 'PDF' ? <PictureAsPdf /> : <PhotoCamera />;

const DocsList: React.FC<DocsListProps> = ({ items }) => (
  <Box>
    <Typography fontWeight={600}>Documentos complementares</Typography>
    <Box sx={{ pt: 2 }}>
      {items.map(({ id, nome, documento, extensao }) => (
        <Chip
          key={id}
          icon={getIconByExtension(extensao)}
          label={nome}
          onClick={() => {
            const mimeType = files.getMimeTypeFromExtension(extensao);
            const file = files.getFileFromBase64(documento, nome, mimeType);

            files.openFile(file);
          }}
          sx={{ mr: 1, mb: 1 }}
        />
      ))}
    </Box>
  </Box>
);

export default DocsList;
