import Lottie, { Options } from 'react-lottie';
import { ErrorAnimation } from 'assets/lotties';
import { Grid, Typography } from '@mui/material';

const defaultLottieOptions: Options = {
  loop: false,
  autoplay: true,
  animationData: ErrorAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const AnimatedError: React.FC = ({ children }) => (
  <Grid container justifyContent="center" alignItems="center">
    <Grid
      item
      sx={{
        fontSize: '1rem',
        fontWeight: 200,
      }}
    >
      <Typography>{children}</Typography>
    </Grid>
    <Grid item>
      <Lottie options={defaultLottieOptions} />
    </Grid>
  </Grid>
);

export default AnimatedError;
