import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  CakeOutlined,
  Female,
  Male,
  MonitorWeightOutlined,
  StraightenOutlined,
  ChildFriendly,
  Boy,
  Man,
  Elderly,
} from '@mui/icons-material';
import { Box, Grid, Skeleton, Stack, Tooltip, Typography } from '@mui/material';
import { ChipImc, ImcInfo, PendenciaProps, RespostaProps } from 'components';
import { useAxios } from 'hooks';

type Info = {
  protocol?: string;
  altura: string;
  peso: string;
  dataNascimento: string;
  sexo: 'F' | 'M';
  idBeneficiario: string;
  updatePendencies?: () => void;
  disabled?: boolean;
};

const GENDERS = {
  F: 'Feminino',
  M: 'Masculino',
};

const BeneficiarioInfo: React.FC<Info> = ({
  altura,
  peso,
  dataNascimento,
  sexo,
  idBeneficiario,
  updatePendencies,
  disabled,
  protocol,
}) => {
  const { api } = useAxios();

  const [imcQuestining, setImcQuestining] = useState(false);
  const [showSkeletonPendency, setShowSkeletonPendency] = useState(false);
  const [pendencies, setPendencies] = useState<PendenciaProps[]>([]);

  const fetchPendencies = useCallback((): void => {
    const url = `${process.env.REACT_APP_MS_AUDITORIA_URL}/perguntas/listar-perguntas-resposta-documento-imc`;
    const params = { protocolo: protocol, idBeneficiario };

    setShowSkeletonPendency(true);

    api
      .get(url, { params })
      .then(({ data }) => {
        setPendencies(data);
      })
      .finally(() => {
        setShowSkeletonPendency(false);
      });
  }, [api, idBeneficiario, protocol]);

  const updateResposta = (respostas: RespostaProps[]): void => {
    const url = `${process.env.REACT_APP_MS_AUDITORIA_URL}/respostas`;

    setShowSkeletonPendency(true);

    const promises = respostas.map(resposta =>
      api.post(`${url}/${resposta.id}/viewed`)
    );

    Promise.all(promises)
      .then(() => {
        if (updatePendencies) {
          updatePendencies();
        }
      })
      .finally(() => {
        setShowSkeletonPendency(false);
      });
  };

  const unviewedPendencies = useMemo(
    () =>
      pendencies
        .filter(p => !!p.resposta.id && !p.resposta?.dataVisualizacao)
        .map(p => p.resposta),
    [pendencies]
  );

  const openQuestioning = (): void => {
    fetchPendencies();
    setImcQuestining(true);
    if (unviewedPendencies.length > 0) {
      updateResposta(unviewedPendencies);
    }
  };

  const closeQuestioning = (): void => {
    setImcQuestining(false);
    fetchPendencies();
    if (updatePendencies) {
      updatePendencies();
    }
  };

  useEffect(() => {
    fetchPendencies();
  }, []);

  const notificationImc =
    unviewedPendencies.length > 0 ? 'contained' : 'outlined';

  const alturaNumber = useMemo(
    () => Number(altura.trim().replace(',', '.')),
    [altura]
  );
  const pesoNumber = useMemo(
    () => Number(peso.trim().replace(',', '.')),
    [peso]
  );

  const date = dataNascimento;
  const format = date.split('/');
  const formatDate = `${format[1]}/${format[0]}/${format[2]}`;

  const actualDay = new Date().getTime();
  const birth = new Date(formatDate).getTime();

  const difference = Math.abs(actualDay - birth);
  const convert = difference / (1000 * 60 * 60 * 24 * 365.242189);
  const age = Math.trunc(convert);
  const months = (convert * 12).toFixed();

  return (
    <Box>
      {showSkeletonPendency && <Skeleton variant="text" />}
      <Grid container columnGap={5}>
        <Grid item>
          <Tooltip title="Altura em metros">
            <Stack direction="row" spacing={1}>
              <StraightenOutlined />
              <Typography component="span">{`${altura} m`}</Typography>
            </Stack>
          </Tooltip>
        </Grid>

        <Grid item>
          <Tooltip title="Peso em quilogramas">
            <Stack direction="row" spacing={1}>
              <MonitorWeightOutlined />
              <Typography component="span">{`${peso} kg`}</Typography>
            </Stack>
          </Tooltip>
        </Grid>

        <Grid item>
          <Tooltip title="Data de nascimento">
            <Stack direction="row" spacing={1}>
              <CakeOutlined />
              <Typography component="span">{dataNascimento}</Typography>
            </Stack>
          </Tooltip>
        </Grid>

        <Grid item>
          <Tooltip title="Idade">
            <Stack direction="row" spacing={1}>
              {age <= 2 ? (
                <ChildFriendly />
              ) : age > 3 && age < 17 ? (
                <Boy />
              ) : age > 18 && age < 59 ? (
                <Man />
              ) : age > 60 ? (
                <Elderly />
              ) : (
                <Man />
              )}
              <Typography component="span">
                {age < 1
                  ? `${months} meses`
                  : age === 1
                  ? `${age} ano`
                  : `${age} anos`}
              </Typography>
            </Stack>
          </Tooltip>
        </Grid>

        <Grid item>
          <Tooltip title="Gênero">
            <Stack direction="row" spacing={1}>
              {sexo === 'F' && <Female />}
              {sexo === 'M' && <Male />}
              <Typography component="span">{GENDERS[sexo]}</Typography>
            </Stack>
          </Tooltip>
        </Grid>

        <Grid item>
          <Stack direction="row" spacing={1}>
            <ChipImc
              notification={notificationImc}
              altura={alturaNumber}
              peso={pesoNumber}
              handleClick={openQuestioning}
            />
          </Stack>
        </Grid>
      </Grid>
      <ImcInfo
        altura={`${alturaNumber}`}
        handleOpen={imcQuestining}
        handleClose={closeQuestioning}
        idBeneficiario={idBeneficiario}
        imc={`${pesoNumber / (alturaNumber * alturaNumber)}`}
        pendencies={pendencies}
        peso={`${pesoNumber}`}
        unviewedPendencies={unviewedPendencies}
        updatePendencies={fetchPendencies}
        disabled={disabled}
      />
    </Box>
  );
};

export default BeneficiarioInfo;
