import { useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { Delete } from '@mui/icons-material';

import { useAxios, useUi } from 'hooks';

type Cid10ItemProps = {
  codigo: string;
  nome: string;
  versao: string;
  isTitular: boolean;
  idBeneficiario: string;
  showActions: boolean;
  disable?: boolean;
};

const Cid10Item: React.FC<Cid10ItemProps> = ({
  codigo,
  nome,
  isTitular,
  idBeneficiario,
  showActions,
  disable,
}) => {
  const [showModalDelete, setShowModalDelete] = useState(false);

  const { api } = useAxios();
  const { protocolo } = useParams();
  const { errorMessage, successMessage } = useUi();

  const handleOpenModalDelete = (): void => {
    setShowModalDelete(true);
  };

  const handleCloseModalDelete = (): void => {
    setShowModalDelete(false);
  };

  const onDeleteTitular = (): void => {
    const url = `${process.env.REACT_APP_MS_AUDITORIA_URL}/cid10-dados-beneficiario/titular`;
    const params = {
      protocolo,
      cid10Codigo: codigo,
      idTitular: idBeneficiario,
    };

    api
      .delete(url, { params })
      .then(() => {
        successMessage('Removido com sucesso!');
        handleCloseModalDelete();
        window.location.reload();
      })
      .catch(error => {
        if (!error.response) {
          errorMessage('Não foi possível remover o registro.');
        }
        if (error.response.status === 400) {
          const { message } = error.response.data.errorMessage[0];
          errorMessage(message);
        }
      });
  };

  const onDeleteDependente = (): void => {
    const url = `${process.env.REACT_APP_MS_AUDITORIA_URL}/cid10-dados-beneficiario/dependente`;
    const params = {
      protocolo,
      cid10Codigo: codigo,
      idDependente: idBeneficiario,
    };

    api
      .delete(url, { params })
      .then(() => {
        successMessage('Removido com sucesso!');
        handleCloseModalDelete();
        window.location.reload();
      })
      .catch(error => {
        if (!error.response) {
          errorMessage('Não foi possível remover o registro.');
        }
        if (error.response.status === 400) {
          const { message } = error.response.data.errorMessage[0];
          errorMessage(message);
        }
      });
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          '&:hover': { bgcolor: '#f9f9f9' },
          width: '100%',
          p: 1,
        }}
      >
        <Box width="80%">
          <Typography>{`${codigo} - ${nome}`}</Typography>
        </Box>
        {!disable && showActions && (
          <Box>
            <Tooltip title="Remover" placement="top">
              <IconButton
                aria-label="delete an item"
                size="small"
                onClick={handleOpenModalDelete}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Box>

      <Dialog
        fullWidth
        maxWidth="md"
        open={showModalDelete}
        onClose={handleCloseModalDelete}
      >
        <DialogTitle>Deseja remover este CID?</DialogTitle>
        <DialogContent>
          <Typography>Clique em confirmar para remover.</Typography>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseModalDelete}>Cancelar</Button>
          <Button
            onClick={isTitular ? onDeleteTitular : onDeleteDependente}
            autoFocus
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Cid10Item;
