import React, { useCallback, useEffect, useMemo } from 'react';
import { Outlet, useSearchParams } from 'react-router-dom';
import api from 'axios';

import { Box, Container, Grid } from '@mui/material';
import { PublicLayoutProvider } from 'context';
import { CpfValidation, LoadingLayout, TitularHeader } from 'components';
import { usePublicLayout, useTheme } from 'hooks';

type CustomLayoutProps = {
  children: JSX.Element;
};

const CustomLayout: React.FC<CustomLayoutProps> = ({ children }) => {
  const { setLayout, layoutIsEmpty } = useTheme();
  const [searchParams] = useSearchParams();
  const { setProtocolo } = usePublicLayout();

  const protocolo = useMemo(
    () => searchParams.get('protocolo'),
    [searchParams],
  );

  const fetchParametrizacaoLayout = useCallback(() => {
    const url = `${process.env.REACT_APP_MS_AGENDA_URL}/parametrizacao-layout/protocolo/${protocolo}`;

    api.get(url).then(({ data }) => {
      setLayout(data.layout);
    });
  }, [protocolo, setLayout]);

  useEffect(() => {
    if (protocolo) {
      setProtocolo(protocolo);
    }
  }, [protocolo, setProtocolo]);

  useEffect(() => {
    if (protocolo) {
      fetchParametrizacaoLayout();
    }
  }, [fetchParametrizacaoLayout, protocolo]);

  if (layoutIsEmpty) {
    return <LoadingLayout />;
  }

  return children;
};

const AuthenticatedOutlet: React.FC = () => {
  const { isAuthenticated } = usePublicLayout();

  return isAuthenticated ? <Outlet /> : <CpfValidation />;
};

const PublicLayout: React.FC = () => (
  <PublicLayoutProvider>
    <CustomLayout>
      <Box sx={{ width: '100vw', height: '100vh' }}>
        <TitularHeader />
        <Grid
          container
          sx={{ width: '100%', height: '100%' }}
          justifyContent="center"
        >
          <Grid item lg={10} md={9} sm={12}>
            <Box sx={{ mt: 10 }}>
              <Container maxWidth="lg">
                <AuthenticatedOutlet />
              </Container>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </CustomLayout>
  </PublicLayoutProvider>
);

export default PublicLayout;
