import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
} from '@mui/material';
import { Sell } from '@mui/icons-material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useParams } from 'react-router-dom';

import {
  Table,
  DiseaseRow,
  DiseaseProps,
  Cid10List,
  RecusaPanel,
  Cid10DadosBeneficiario,
} from 'components';
import { PerguntaType } from 'context';
import { useAxios, useDeclaracaoSaude, useUi } from 'hooks';

type RowProps = DiseaseProps & {
  rowBackground: 'default' | 'primary';
  alteracoes: boolean;
};

type DiseaseListProps = {
  idBeneficiario: string;
  diseases: PerguntaType[];
  onUpdatePendencias: () => void;
  isTitular: boolean;
  unviewedRows: string[];
  disable: boolean;
  protocol: string;
};

const DiseaseList: React.FC<DiseaseListProps> = ({
  idBeneficiario,
  diseases,
  onUpdatePendencias,
  isTitular,
  unviewedRows,
  disable,
  protocol,
}) => {
  const { api } = useAxios();
  const {
    showBackdrop,
    hideBackdrop,
    successMessage,
    errorMessage,
    loadCid10,
  } = useUi();
  const { protocolo } = useParams();
  const { status, updateStatus } = useDeclaracaoSaude();

  const [currentDisease, setCurrentDisease] = useState<DiseaseProps | null>(
    null
  );
  const [showDiseaseDetails, setShowDiseaseDetails] = useState(false);
  const [cid10ListTitular, setCid10ListTitular] = useState<
    Cid10DadosBeneficiario[]
  >([]);
  const [cid10ListDependente, setCid10ListDependente] = useState<
    Cid10DadosBeneficiario[]
  >([]);

  const knownDiseases: PerguntaType[] = useMemo(
    () => diseases.filter(disease => disease.possui),
    [diseases]
  );
  const declaracaoStatus = useMemo(() => status ?? '', [status]);
  const disableDiseaseAction = useMemo(
    () =>
      ['PENDENTE DE RESPOSTA', 'PENDENTE DE ACEITE', 'RECUSADO'].includes(
        declaracaoStatus
      ),
    [declaracaoStatus]
  );
  const disableCid10Action = useMemo(
    () => ['PENDENTE DE ACEITE'].includes(declaracaoStatus),
    [declaracaoStatus]
  );

  const columns: GridColDef[] = [
    {
      field: 'alteracoes',
      headerName: '',
      flex: 0.2,
      renderCell: (obj: GridRenderCellParams) => [
        [
          obj.row.alteracoes && (
            <Box textAlign="center">
              <Tooltip title="Informações Adicionais">
                <Sell />
              </Tooltip>
            </Box>
          ),
        ],
      ],
    },
    { field: 'titulo', headerName: 'Doença', flex: 1 },
    { field: 'doenca', headerName: 'Categoria', flex: 1 },
    { field: 'tipo', headerName: 'Tipo', flex: 1 },
    { field: 'tempo', headerName: 'Tempo', flex: 1 },
    { field: 'local', headerName: 'Local', flex: 1 },
    { field: 'grau', headerName: 'Grau', flex: 1 },
    {
      field: 'especificacaoManual',
      headerName: 'Detalhes',
      flex: 1,
    },
    {
      field: 'especificacaoManualAlteracoes',
      headerName: 'Detalhes',
      flex: 1,
    },
  ];

  const rows = useMemo(() => {
    const response: RowProps[] = [];

    knownDiseases.forEach(disease => {
      const {
        id,
        titulo,
        especificacao,
        especificacaoManual,
        especificacaoManualAlteracoes,
      } = disease;

      especificacao
        .filter(e => e.portador)
        .forEach((e, i) => {
          const tableRow: RowProps = {
            id: `${id}_${i}`,
            alteracoes: !!especificacaoManualAlteracoes,
            doenca: titulo,
            titulo: e.titulo,
            tipo: e.tipo ?? '',
            tempo: e.tempo ?? '',
            local: e.local ?? '',
            grau: e.grau ?? '',
            especificacaoManual: especificacaoManual ?? '',
            especificacaoManualAlteracoes: especificacaoManualAlteracoes ?? '',
            rowBackground: unviewedRows.includes(`${id}_${i}`)
              ? 'primary'
              : 'default',
          };

          response.push(tableRow);
        });
    });

    return response;
  }, [knownDiseases, unviewedRows]);

  const fetchCid10Titular = useCallback((): void => {
    const url = `${
      process.env.REACT_APP_MS_AUDITORIA_URL
    }/cid10-dados-beneficiario/titular/${protocol ?? protocolo}`;
    const params = { idTitular: idBeneficiario };

    api.get(url, { params }).then(({ data }) => {
      setCid10ListTitular(data.cid10s ?? []);
    });
  }, [api, idBeneficiario, protocolo]);

  const fetchCid10Dependente = useCallback((): void => {
    const url = `${
      process.env.REACT_APP_MS_AUDITORIA_URL
    }/cid10-dados-beneficiario/dependente/${protocol ?? protocolo}`;
    const params = { idDependente: idBeneficiario };

    api.get(url, { params }).then(({ data }) => {
      const arr = data?.dependenteCid10s?.[0]?.cid10s || [];
      setCid10ListDependente(arr);
    });
  }, [api, idBeneficiario, protocolo]);

  const fetchCid10 = useCallback((): void => {
    if (isTitular) {
      fetchCid10Titular();
    } else {
      fetchCid10Dependente();
    }
  }, [fetchCid10Dependente, fetchCid10Titular, isTitular]);

  const saveCid10 = (listaCids: string[]): void => {
    const url = `${process.env.REACT_APP_MS_AUDITORIA_URL}/cid10-dados-beneficiario/cids`;
    const data = { idBeneficiario, listaCids, protocolo };

    showBackdrop();

    api
      .post(url, data)
      .then(() => {
        successMessage('CID10 registrado com sucesso!');
        fetchCid10();
        loadCid10();
        updateStatus('EM AUDITORIA');
      })
      .catch(() => {
        errorMessage('Não foi possível registrar o CID10!');
      })
      .finally(() => {
        hideBackdrop();
      });
  };

  useEffect(() => {
    setShowDiseaseDetails(!!currentDisease);
  }, [currentDisease]);

  useEffect(() => {
    fetchCid10();
  }, [fetchCid10]);

  return (
    <Box sx={{ marginTop: 2 }}>
      <Table
        rows={rows}
        columns={columns}
        autoHeight
        hideFooterPagination
        isRowSelectable={() => !disableDiseaseAction}
        onRowClick={params =>
          disableDiseaseAction ? undefined : setCurrentDisease(params.row)
        }
        onRowDoubleClick={() => setCurrentDisease(null)}
        sx={{
          '& .MuiDataGrid-row': {
            maxHeight: 'none !important',
            '&:hover': { cursor: 'pointer' },
          },
          '& .row-background-primary': {
            fontWeight: 'bold',
            bgcolor: 'rgba(0, 0, 0, 0.04)',
          },
          '& .row-background-default': {
            fontWeight: 'normal',
          },
        }}
        initialState={{
          columns: {
            columnVisibilityModel: {
              especificacaoManualAlteracoes: false,
            },
          },
        }}
        getRowClassName={params => `row-background-${params.row.rowBackground}`}
      />

      <Box sx={{ marginTop: 2 }}>
        <Cid10List
          isTitular={isTitular}
          cid10DadosBeneficiario={
            isTitular ? cid10ListTitular : cid10ListDependente
          }
          handleNewCid10={cid10 => saveCid10(cid10.map(c => c.codigo))}
          showActions={!disableCid10Action}
          idBeneficiario={idBeneficiario}
          disable={disable}
        />
      </Box>

      <Box sx={{ mt: 2 }}>
        <RecusaPanel
          idBeneficiario={idBeneficiario}
          isTitular={isTitular}
          protocol={protocol}
        />
      </Box>

      {currentDisease && showDiseaseDetails && (
        <Dialog
          fullWidth
          maxWidth="md"
          open
          onClose={() => {
            setCurrentDisease(null);
            setShowDiseaseDetails(false);
          }}
        >
          <DialogTitle>Detalhes da Doença</DialogTitle>
          <DialogContent>
            <DiseaseRow
              protocol={protocol}
              disease={currentDisease}
              idBeneficiario={idBeneficiario}
              disable={disable}
            />
          </DialogContent>

          <DialogActions>
            <Button
              onClick={() => {
                setCurrentDisease(null);
                setShowDiseaseDetails(false);
                onUpdatePendencias();
              }}
            >
              Fechar
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
};

export default DiseaseList;
