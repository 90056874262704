import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { useAxios, useUi } from 'hooks';
import { Cid10Item } from 'components';

export type Cid10 = {
  codigo: string;
  nome: string;
  versao: string;
};

export type MedicoAuditor = {
  crm: string;
  especialidade: string;
  id: number;
  idProfissional: number;
  nome: string;
};

export type Cid10DadosBeneficiario = {
  cid10: Cid10;
  medicoAuditor: MedicoAuditor;
};

type Cid10Props = {
  cid10DadosBeneficiario: Cid10DadosBeneficiario[];
  handleNewCid10: (cid10: Cid10[]) => void;
  showActions?: boolean;
  idBeneficiario: string;
  isTitular: boolean;
  disable?: boolean;
};

type FormValues = {
  observacao: string;
};

const MIN_LENGTH_INPUT_TEXT = 10;
const MAX_LENGTH_INPUT_TEXT = 300;

const schema = yup.object().shape({
  observacao: yup
    .string()
    .min(
      MIN_LENGTH_INPUT_TEXT,
      `O tamanho mínimo é de ${MIN_LENGTH_INPUT_TEXT} caracteres`
    )
    .max(
      MAX_LENGTH_INPUT_TEXT,
      `O tamanho máximo é de ${MAX_LENGTH_INPUT_TEXT} caracteres`
    )
    .required('Este campo é obrigatório'),
});

const Cid10List: React.FC<Cid10Props> = ({
  cid10DadosBeneficiario,
  handleNewCid10,
  showActions = true,
  idBeneficiario,
  isTitular,
  disable,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [showObsModal, setShowObsModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedValue, setSelectedValue] = useState<Cid10[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [optionsCid10, setOptionsCid10] = useState<Cid10[]>([]);
  const [obsTitular, setObsTitular] = useState('');
  const [obsDependente, setObsDependente] = useState('');

  const { api } = useAxios();
  const { hideBackdrop, showBackdrop, successMessage, errorMessage } = useUi();
  const { protocolo } = useParams();
  const { control, formState, handleSubmit, reset } = useForm<FormValues>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      observacao: '',
    },
  });

  const handleOpenModal = (): void => {
    setShowModal(true);
  };

  const handleCloseModal = (reason: unknown | undefined): void => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    setShowModal(false);
    setSelectedValue([]);
  };

  const handleCloseConfirmModal = (): void => {
    setShowConfirmModal(false);
  };

  const handleOpenObsModal = (): void => {
    setShowObsModal(true);
  };

  const handleCloseObsModal = (): void => {
    setShowObsModal(false);
    reset();
  };

  const onClickConfirm = (): void => {
    showBackdrop();

    api
      .post(
        `${process.env.REACT_APP_MS_AUDITORIA_URL}/notificacao/enviar-cid10/${protocolo}/idBeneficiario/${idBeneficiario}`
      )
      .then(() => {
        successMessage('A análise da declaração de saúde foi enviada');
        handleCloseConfirmModal();
      })
      .catch(err => {
        if (
          err.response.status === 400 &&
          err.response.data?.errorMessage?.[0]?.message
        ) {
          errorMessage(err.response.data?.errorMessage?.[0]?.message);
          return;
        }
        errorMessage('Não foi possível enviar o documento!');
      })
      .finally(() => {
        hideBackdrop();
      });
  };

  const fetchCid10 = useCallback(
    (descricao: string): void => {
      const url = `${process.env.REACT_APP_MS_AUDITORIA_URL}/cid10`;
      const params = { descricao };

      api.get(url, { params }).then(({ data }) => {
        setOptionsCid10(data);
      });
    },
    [api]
  );

  const fetchObservacaoTitular = useCallback((): void => {
    const url = `${process.env.REACT_APP_MS_AUDITORIA_URL}/observacao/titular/${protocolo}`;
    const params = { idTitular: idBeneficiario };

    api
      .get(url, { params })
      .then(({ data }) => {
        setObsTitular(data.texto);
      })
      .catch(() => {
        setObsTitular('');
      });
  }, [api, idBeneficiario, protocolo]);

  const fetchObservacaoDependente = useCallback((): void => {
    const url = `${process.env.REACT_APP_MS_AUDITORIA_URL}/observacao/dependente/${protocolo}`;
    const params = { idDependente: idBeneficiario };

    api
      .get(url, { params })
      .then(({ data }) => {
        setObsDependente(data.texto);
      })
      .catch(() => {
        setObsDependente('');
      });
  }, [api, idBeneficiario, protocolo]);

  const onSubmit: SubmitHandler<FormValues> = ({ observacao }) => {
    let url = `${process.env.REACT_APP_MS_AUDITORIA_URL}/observacao`;

    if (obsTitular || obsDependente) {
      url = `${process.env.REACT_APP_MS_AUDITORIA_URL}/observacao/atualizar/${protocolo}`;
    }

    const data = { idBeneficiario, texto: observacao, protocolo };

    showBackdrop();
    api
      .post(url, data)
      .then(() => {
        successMessage('Operação realizada com sucesso!');
        handleCloseObsModal();
      })
      .catch(() => {
        errorMessage('Erro ao realizar operação');
      })
      .finally(() => {
        hideBackdrop();
      });
  };

  // Side Effects

  useEffect(() => {
    reset({ observacao: isTitular ? obsTitular : obsDependente });
  }, [obsTitular, obsDependente, reset, isTitular]);

  useEffect(() => {
    if (inputValue.length >= 3) {
      fetchCid10(inputValue);
    }
  }, [fetchCid10, inputValue]);

  useEffect(() => {
    if (!showObsModal) {
      return;
    }
    if (isTitular) {
      fetchObservacaoTitular();
    } else {
      fetchObservacaoDependente();
    }
  }, [
    showObsModal,
    isTitular,
    fetchObservacaoTitular,
    fetchObservacaoDependente,
  ]);

  return (
    <Paper sx={{ p: 2 }}>
      {cid10DadosBeneficiario.length === 0 && (
        <Typography>Nenhum CID-10 foi registrado.</Typography>
      )}

      {cid10DadosBeneficiario.length > 0 && (
        <Grid container>
          {cid10DadosBeneficiario.map(({ cid10 }) => (
            <Cid10Item
              key={cid10.codigo}
              codigo={cid10.codigo}
              nome={cid10.nome}
              versao={cid10.versao}
              isTitular={isTitular}
              idBeneficiario={idBeneficiario}
              showActions={showActions}
              disable={disable}
            />
          ))}
        </Grid>
      )}

      {showActions && (
        <Box sx={{ display: disable ? 'none' : '', mt: 3 }}>
          <ButtonGroup>
            <Button
              size="small"
              variant="text"
              onClick={handleOpenModal}
              disabled={disable}
            >
              Adicionar CID10
            </Button>
            {cid10DadosBeneficiario.length > 0 && (
              <Button
                size="small"
                variant="text"
                onClick={handleOpenObsModal}
                disabled={disable}
              >
                Observação
              </Button>
            )}
          </ButtonGroup>
        </Box>
      )}

      <Dialog
        fullWidth
        maxWidth="sm"
        open={showModal}
        onClose={() => {
          handleCloseModal('backdropClick');
        }}
      >
        <DialogTitle>Adicionar CID-10</DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            <Autocomplete
              multiple
              value={selectedValue}
              onChange={(event, value) => {
                setSelectedValue(value);
              }}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue as string);
              }}
              options={optionsCid10}
              getOptionLabel={option => `(${option.codigo}) ${option.nome}`}
              renderInput={params => (
                <TextField {...params} label="Descrição" />
              )}
            />
          </Box>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseModal}>Cancelar</Button>
          <Button
            onClick={() => {
              if (selectedValue) {
                handleNewCid10(selectedValue);
                handleCloseModal('');
              }
            }}
            disabled={!selectedValue}
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth
        maxWidth="sm"
        open={showObsModal}
        onClose={handleCloseObsModal}
      >
        <DialogTitle>Observação</DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            <form>
              <Controller
                control={control}
                name="observacao"
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    multiline
                    inputProps={{ maxLength: MAX_LENGTH_INPUT_TEXT }}
                    variant="standard"
                    label="Observação"
                    error={!!formState.errors?.observacao}
                    helperText={`${field.value.length}/${MAX_LENGTH_INPUT_TEXT}`}
                  />
                )}
              />
            </form>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseObsModal}>Cancelar</Button>
          <Button
            onClick={handleSubmit(onSubmit)}
            disabled={!formState.isValid}
            variant="contained"
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth
        maxWidth="md"
        open={showConfirmModal}
        onClose={handleCloseConfirmModal}
      >
        <DialogTitle>
          Deseja enviar a análise de declaração de saúde?
        </DialogTitle>
        <DialogContent>
          <Typography>
            Após enviar a análise de declaração de saúde ao titular{' '}
            <b>não será possível alterá-la</b>. Confira todos os CIDs inseridos
            antes de clicar em confirmar.
          </Typography>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseConfirmModal}>Cancelar</Button>
          <Button onClick={onClickConfirm} autoFocus>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default Cid10List;
