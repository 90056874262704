import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { Check, Close, Delete, Edit } from '@mui/icons-material';
import { PerguntaProps } from 'components/DiseaseRow/DiseaseRow';

import { useAxios, useUi } from 'hooks';

type PendenciaItemProps = {
  pergunta: PerguntaProps;
  handleClose: () => void;
  disabled?: boolean;
};

type FormValues = {
  perguntaInput: string;
};

const MIN_LENGTH_INPUT_TEXT = 10;
const MAX_LENGTH_INPUT_TEXT = 300;

const schema = yup.object().shape({
  perguntaInput: yup
    .string()
    .min(
      MIN_LENGTH_INPUT_TEXT,
      `O tamanho mínimo é de ${MIN_LENGTH_INPUT_TEXT} caracteres`
    )
    .max(
      MAX_LENGTH_INPUT_TEXT,
      `O tamanho máximo é de ${MAX_LENGTH_INPUT_TEXT} caracteres`
    )
    .required('Este campo é obrigatório'),
});

const PendenciaItem: React.FC<PendenciaItemProps> = ({
  pergunta,
  handleClose,
  disabled,
}) => {
  const [showModalUpdate, setShowModalUpdate] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);

  const { api } = useAxios();
  const { protocolo } = useParams();
  const { errorMessage, successMessage } = useUi();
  const { control, formState, handleSubmit, reset } = useForm<FormValues>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      perguntaInput: pergunta.pergunta,
    },
  });

  const handleOpenModalUpdate = (): void => {
    setShowModalUpdate(true);
  };

  const handleCloseModalUpdate = (): void => {
    setShowModalUpdate(false);
    handleClose();
    reset();
  };

  const handleOpenModalDelete = (): void => {
    setShowModalDelete(true);
  };

  const handleCloseModalDelete = (): void => {
    setShowModalDelete(false);
    handleClose();
  };

  const onUpdate: SubmitHandler<FormValues> = ({ perguntaInput }) => {
    const url = `${process.env.REACT_APP_MS_AUDITORIA_URL}/perguntas/atualizar-pergunta`;
    const params = { protocolo };
    const body = {
      perguntaId: pergunta.id,
      pergunta: perguntaInput,
    };

    return api
      .post(url, body, { params })
      .then(() => {
        successMessage('Atualizado com sucesso!');
        handleCloseModalUpdate();
      })
      .catch(error => {
        if (!error.response) {
          errorMessage('Não foi possível registrar a pendência.');
        }
        if (error.response.status === 400) {
          const { message } = error.response.data.errorMessage[0];
          errorMessage(message);
        }
      });
  };

  const onDelete = (): void => {
    const url = `${process.env.REACT_APP_MS_AUDITORIA_URL}/perguntas/deletar-pergunta`;
    const params = { protocolo };
    const body = { perguntaId: pergunta.id };

    api
      .post(url, body, { params })
      .then(() => {
        successMessage('Removido com sucesso!');
        handleCloseModalDelete();
      })
      .catch(error => {
        if (!error.response) {
          errorMessage('Não foi possível remover a pendência.');
        }
        if (error.response.status === 400) {
          const { message } = error.response.data.errorMessage[0];
          errorMessage(message);
        }
      });
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 1,
        }}
      >
        {showModalUpdate && (
          <>
            <Controller
              control={control}
              name="perguntaInput"
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  variant="outlined"
                  inputProps={{ maxLength: MAX_LENGTH_INPUT_TEXT }}
                  label="Descreva sua pergunta"
                  error={!!formState.errors.perguntaInput}
                  helperText={`${field.value.length}/${MAX_LENGTH_INPUT_TEXT}`}
                />
              )}
            />
            <IconButton onClick={handleCloseModalUpdate}>
              <Close />
            </IconButton>
            <IconButton
              autoFocus
              disabled={
                !formState.isValid ||
                !formState.isDirty ||
                formState.isSubmitting
              }
              onClick={handleSubmit(onUpdate)}
            >
              <Check />
            </IconButton>
          </>
        )}

        {!showModalUpdate && (
          <>
            <Box width="80%">
              <Typography sx={{ fontWeight: 700 }}>
                Pergunta: {pergunta.pergunta}
              </Typography>
            </Box>
            {!disabled && pergunta.status.id === 1 && (
              <Box>
                <Tooltip title="Editar" placement="top">
                  <IconButton
                    aria-label="update an item"
                    size="small"
                    onClick={handleOpenModalUpdate}
                  >
                    <Edit />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Remover" placement="top">
                  <IconButton
                    aria-label="delete an item"
                    size="small"
                    onClick={handleOpenModalDelete}
                  >
                    <Delete />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          </>
        )}
      </Box>

      <Dialog
        fullWidth
        maxWidth="md"
        open={showModalDelete}
        onClose={handleCloseModalDelete}
      >
        <DialogTitle>Deseja remover esta pendência?</DialogTitle>
        <DialogContent>
          <Typography>Confirme para remover esta pendência.</Typography>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseModalDelete}>Cancelar</Button>
          <Button variant="contained" onClick={onDelete} autoFocus>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PendenciaItem;
