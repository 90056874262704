import { memo, useEffect, useMemo, useState } from 'react';

import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Box, Grid, Typography } from '@mui/material';

import WarningAmberIcon from '@mui/icons-material/WarningAmber';

import { ModalChat } from 'components';
import { usePublicLayout } from 'hooks';
import { api } from 'services';
import { token as storage } from 'utils';

const Chat: React.FC = () => {
  const [linkIsValid, setLinkIsValid] = useState(false);
  const { isAuthenticated } = usePublicLayout();
  const { link } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const protocolo = useMemo(
    () => searchParams.get('protocolo') || '',
    [searchParams]
  );

  useEffect(() => {
    const validaLink = (): void => {
      const url = `${process.env.REACT_APP_MS_AUDITORIA_URL}/link/${link}`;
      const headers = { Authorization: `Bearer ${storage.getAccessToken()}` };

      api
        .get(url, { headers })
        .then(({ data }) => {
          if (data.isValid) {
            setLinkIsValid(true);
          } else {
            navigate('/link-invalido');
          }
        })
        .catch(() => {
          navigate('/error');
        });
    };

    if (isAuthenticated) {
      validaLink();
    }
  }, [link, navigate, isAuthenticated]);

  if (!linkIsValid) {
    return null;
  }

  return (
    <Box>
      <Grid container sx={{ '& > :not(style)': { mb: 1 } }}>
        <Grid item md={12}>
          <Box sx={{ marginBottom: 5 }}>
            <Typography variant="h4">Chat com Auditor</Typography>
          </Box>
          <Box>
            <ModalChat
              close={() => console.log('Fechar')}
              hideCloseButton
              title="Auditor"
              maxWidth={500}
              handleProtocol={protocolo}
              issuerMessage="beneficiario"
              controllerColorMessage="lightgray"
              clientColorMessage="#4fff98"
              issuerAlign={0}
              receiverAlign="auto"
              api={api}
            />
          </Box>
          <Box display="flex" alignItems="center" padding={1} marginTop={2}>
            <WarningAmberIcon fontSize="large" />
            <Typography paddingLeft={2} textAlign="justify">
              Este chat não ocorre em tempo real com o médico. Em caso de
              dúvidas entre em contato com o setor de Relacionamento Corporativo
              no telefone <b>(13) 2102-8341</b>.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default memo(Chat);
