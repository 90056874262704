import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAxios, useDeclaracaoSaude, useUi } from 'hooks';
import { useNavigate } from 'react-router-dom';
import { Done, Comment } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Modal,
  Paper,
  Skeleton,
  Tooltip,
  Typography,
} from '@mui/material';

import {
  BeneficiarioInfo,
  DeclaracaoSaudeInfo,
  DiseaseList,
  PageTitle,
  Pendencias,
  PendenciaProps,
  AceitePanel,
  DocumentoBeneficiarioProps,
  DocsList,
  DiseasePreview,
  PastProtocolList,
  PastProtocolListProps,
  ModalChat,
} from 'components';

type ButtonState = {
  tooltipText: string;
  disabled: boolean;
};

type ButtonGroupStateType = {
  btnDone: ButtonState;
};

export type TitularProps = {
  cpf: string;
  email: string;
  idBeneficiario: string;
  nome: string;
  telefone: string;
};

type MessageProps = {
  dataInclusao: string;
  dataVisualizacao?: string;
  id: number;
  liberarAnexo: false;
  link: {
    id: number;
  };
  medicoAuditor: {
    id: number;
  };
  mensagem: string;
  protocolo: {
    id: number;
  };
  tipoMensagem: {
    id: number;
  };
};

const BUTTON_GROUP_STATE: ButtonGroupStateType = {
  btnDone: {
    tooltipText: 'Finalize a auditoria',
    disabled: false,
  },
};

const DeclaracaoSaudeDetails: React.FC = () => {
  const navigate = useNavigate();
  const { errorMessage, successMessage } = useUi();
  const { api } = useAxios();
  const {
    formulario,
    protocolo,
    status,
    updateStatus,
    tipoPreenchimento,
    operadora,
  } = useDeclaracaoSaude();

  const [openModalFinish, setOpenModalFinish] = useState(false);
  const [openModalSendAccept, setOpenModalSendAccept] = useState(false);
  const [openChat, setOpenChat] = useState(false);
  const [notificationChat, setNotificationChat] = useState(false);
  const [titular, setTitular] = useState<TitularProps | null>(null);
  const [pendencias, setPendencias] = useState<PendenciaProps[]>([]);
  const [buttonGroupState, setButtonGroupState] =
    useState<ButtonGroupStateType>(BUTTON_GROUP_STATE);

  const [protocolosAnteriores, setProtocolosAnteriores] = useState<{
    [key: string]: PastProtocolListProps[];
  }>({});
  const [fetchingProtocolosAnteriores, setFetchingProtocolosAnteriores] =
    useState<'TODO' | 'IN_PROGRESS' | 'DONE'>('TODO');
  const [documentos, setDocumentos] = useState<DocumentoBeneficiarioProps[]>(
    []
  );
  const [fetchingDocumentos, setFetchingDocumentos] = useState<
    'TODO' | 'IN_PROGRESS' | 'DONE'
  >('TODO');

  const pendenciasNaoEnviadas = useMemo(
    () => pendencias.filter(p => p.pergunta.status.id === 1),
    [pendencias]
  );

  const pendenciasNaoVisualizadas = useMemo(
    () =>
      pendencias.filter(p => !!p.resposta.id && !p.resposta?.dataVisualizacao),
    [pendencias]
  );
  const beneficiarios = useMemo(
    () => formulario?.beneficiarios ?? [],
    [formulario]
  );

  const handleOpenModalFinish = (): void => {
    setOpenModalFinish(true);
  };

  const handleCloseModalFinish = (): void => {
    setOpenModalFinish(false);
  };

  const handleCloseModalSendAccept = (): void => {
    setOpenModalSendAccept(false);
  };

  const handleCloseChat = (): void => {
    setOpenChat(false);
  };

  const fetchTitular = useCallback(() => {
    const url = `${process.env.REACT_APP_MS_AGENDA_URL}/preenchimento-formulario/${protocolo}`;

    api.get(url).then(({ data }) => {
      setTitular(data);
    });
  }, [api, protocolo]);

  const nomeTitular = useMemo(() => titular?.nome || '', [titular]);
  const telefoneTitular = useMemo(() => titular?.telefone || '', [titular]);
  const emailTitular = useMemo(() => titular?.email || '', [titular]);

  const fetchPendencias = useCallback((): void => {
    const url = `${process.env.REACT_APP_MS_AUDITORIA_URL}/perguntas/listar-perguntas-resposta-documento`;
    const params = { protocolo };

    api.get(url, { params }).then(({ data }) => {
      setPendencias(data);
    });
  }, [api, protocolo]);

  const fetchProtocolosAnteriores = useCallback(
    (cpf: string): void => {
      const url = `${process.env.REACT_APP_MS_AGENDA_URL}/formulario/formularios-anteriores`;
      const params = { cpf, protocolo };

      setFetchingProtocolosAnteriores('IN_PROGRESS');

      api
        .get(url, { params })
        .then(({ data }) => {
          if (Array.isArray(data) && data.length > 0) {
            setProtocolosAnteriores(prevState => ({
              ...prevState,
              [cpf]: data,
            }));
          }
        })
        .finally(() => {
          setFetchingProtocolosAnteriores('DONE');
        });
    },
    [api, protocolo]
  );

  const fetchDocumentos = useCallback(
    (path: string): void => {
      const url = `${process.env.REACT_APP_MS_AGENDA_URL}/documento-beneficiario/${path}`;

      setFetchingDocumentos('IN_PROGRESS');

      api
        .get(url)
        .then(({ data }) => {
          if (Array.isArray(data) && data.length > 0) {
            setDocumentos(data);
          }
        })
        .finally(() => {
          setFetchingDocumentos('DONE');
        });
    },
    [api]
  );

  const handleOpenChat = (): void => {
    setOpenChat(true);
    setNotificationChat(false);
  };

  const getDocumentos = useCallback(
    (idBeneficiario: string) =>
      documentos.filter(d => d.idBeneficiario === idBeneficiario),
    [documentos]
  );

  const onClickSendAccept = (): void => {
    const url = `${process.env.REACT_APP_MS_AUDITORIA_URL}/protocolo/pendente-aceite`;
    const body = { protocolo };

    api
      .post(url, body)
      .then(() => {
        // updateStatus('PENDENTE DE ACEITE');
        successMessage('Aceite enviado para o proponente.');
        handleCloseModalSendAccept();
      })
      .catch(() => {
        errorMessage('Não foi possível efetuar a ação.');
      });
  };

  const onClickFinish = (): void => {
    const url = `${process.env.REACT_APP_MS_AUDITORIA_URL}/protocolo/concluir`;
    const body = { protocolo };

    api
      .post(url, body)
      .then(() => {
        updateStatus('CONCLUIDO');
        successMessage('Processo de auditoria finalizado.');
        handleCloseModalFinish();
        navigate('/');
      })
      .catch(() => {
        errorMessage('Não foi possível concluir a auditoria.');
      });
  };

  const fetchChatMessages = useCallback((): void => {
    const url = `${process.env?.REACT_APP_MS_AUDITORIA_URL}/chat/lista/protocolo/${protocolo}`;
    const params = { protocolo };

    api
      .get(url, {
        params: { ...params },
      })
      .then(({ data }) => {
        const lastMessage: MessageProps = data[data.length - 1];
        if (
          !lastMessage?.dataVisualizacao &&
          lastMessage?.medicoAuditor.id === 0
        ) {
          setNotificationChat(true);
        }
      });
  }, [api, protocolo]);

  // Side Effects

  useEffect(() => updateStatus(status), [status, updateStatus]);

  useEffect(() => {
    if (fetchingProtocolosAnteriores === 'TODO') {
      beneficiarios.forEach(({ cpf }) => fetchProtocolosAnteriores(cpf));
    }
  }, [beneficiarios, fetchProtocolosAnteriores, fetchingProtocolosAnteriores]);

  useEffect(() => {
    if (protocolo) {
      fetchDocumentos(protocolo);
    }
  }, [fetchDocumentos, protocolo]);

  useEffect(() => {
    switch (status) {
      case 'PENDENTE DE AUDITORIA':
        setButtonGroupState({
          btnDone: {
            disabled: false,
            tooltipText: 'Finalize a auditoria',
          },
        });
        break;

      case 'EM AUDITORIA':
        setButtonGroupState({
          btnDone: {
            disabled: true,
            tooltipText: 'Protocolo não pode ser concluído',
          },
        });
        break;

      // case 'PENDENTE DE RESPOSTA':
      // setButtonGroupState({
      //   btnDone: {
      //     disabled: true,
      //     tooltipText: 'Aguarde a resposta do proponente para concluir',
      //   },
      // });
      // break;

      case 'RESPOSTA EM ANALISE':
        setButtonGroupState({
          btnDone: {
            disabled: false,
            tooltipText: 'Finalize a auditoria',
          },
        });
        break;

      //  case 'PENDENTE DE ACEITE':
      //   setButtonGroupState({
      //     btnDone: {
      //       disabled: true,
      //       tooltipText: 'Protocolo não pode ser concluído',
      //     },
      //   });
      //   break;

      case 'CONCLUIDO':
        setButtonGroupState({
          btnDone: {
            disabled: true,
            tooltipText: 'Protocolo já está concluído',
          },
        });
        break;

      case 'RECUSADO':
        setButtonGroupState({
          btnDone: {
            disabled: true,
            tooltipText: 'Finalize a auditoria',
          },
        });
        break;

      default:
        break;
    }
  }, [status]);

  useEffect(() => {
    if (protocolo) {
      fetchTitular();
      fetchChatMessages();
      fetchPendencias();
    }
  }, [fetchChatMessages, fetchPendencias, fetchTitular, protocolo]);

  if (!titular) {
    return null;
  }

  return (
    <Box>
      <PageTitle>Detalhes do Preenchimento</PageTitle>

      <DeclaracaoSaudeInfo
        nomeTitular={nomeTitular}
        tipoPreenchimento={tipoPreenchimento}
        dataPreenchimento={formulario?.dataPreenchimento}
        protocolo={protocolo}
        obsGeral={formulario?.obsGeral}
        telefone={telefoneTitular}
        email={emailTitular}
      />

      {beneficiarios.map(
        ({
          idBeneficiario,
          nome,
          cpf,
          altura,
          peso,
          dataNasc,
          sexo,
          perguntas,
        }) => (
          <Paper key={idBeneficiario} sx={{ mt: 2, p: 2 }}>
            <Box sx={{ mb: 1 }}>
              <Typography
                fontWeight={600}
                fontSize={20}
                sx={{
                  display: 'table',
                  '&>span': {
                    fontWeight: 400,
                    fontSize: 14,
                    display: 'table-cell',
                    verticalAlign: 'middle',
                    paddingLeft: 1,
                  },
                }}
              >
                {nome}{' '}
                <span>
                  {idBeneficiario === titular.idBeneficiario
                    ? '(TITULAR)'
                    : '(DEPENDENTE)'}
                </span>
              </Typography>
            </Box>
            <Box>
              <BeneficiarioInfo
                altura={altura}
                peso={peso}
                dataNascimento={dataNasc}
                sexo={sexo}
                idBeneficiario={idBeneficiario}
                updatePendencies={fetchPendencias}
                protocol={protocolo}
              />

              <Box sx={{ mt: 2 }}>
                {fetchingProtocolosAnteriores === 'IN_PROGRESS' && (
                  <Skeleton
                    variant="text"
                    sx={{ maxWidth: 450, width: '100%' }}
                  />
                )}
                {fetchingProtocolosAnteriores === 'DONE' &&
                  protocolosAnteriores[cpf]?.length > 0 && (
                    <PastProtocolList
                      items={protocolosAnteriores[cpf]}
                      declaracaoStatus={status}
                      rowDetails={pendenciasNaoVisualizadas.map(
                        p => p.doenca.idDoenca
                      )}
                      idOperadora={operadora}
                      selectedCpf={cpf}
                    />
                  )}
              </Box>

              <Box sx={{ mt: 2, mb: 5 }}>
                {fetchingDocumentos === 'IN_PROGRESS' && (
                  <Skeleton
                    variant="text"
                    sx={{ maxWidth: 450, width: '100%' }}
                  />
                )}
                {fetchingDocumentos === 'DONE' &&
                  getDocumentos(idBeneficiario).length > 0 && (
                    <DocsList items={getDocumentos(idBeneficiario)} />
                  )}
              </Box>

              <DiseaseList
                protocol={protocolo || ''}
                idBeneficiario={idBeneficiario}
                isTitular={idBeneficiario === titular.idBeneficiario}
                diseases={perguntas}
                onUpdatePendencias={() => {
                  fetchPendencias();
                }}
                unviewedRows={pendenciasNaoVisualizadas.map(
                  p => p.doenca.idDoenca
                )}
                disable={false}
              />
            </Box>
          </Paper>
        )
      )}

      <Box sx={{ mt: 5 }}>
        <Typography variant="h5" component="h5" fontWeight={200}>
          Perguntas pendentes para envio
        </Typography>
        <Typography fontSize="1rem" fontWeight={200}>
          Veja as perguntas registradas e prontas para serem enviadas ao
          titular.
        </Typography>
        <Pendencias
          pendencias={pendenciasNaoEnviadas}
          onClose={() => {
            fetchPendencias();
          }}
        />
      </Box>

      <Box sx={{ mt: 5 }}>
        <Typography variant="h5" component="h5" fontWeight={200}>
          Análise da Declaração de Saúde
        </Typography>
        <Typography fontSize="1rem" fontWeight={200}>
          Visualize os CIDs registrados ou reenvie o laudo médico com uma
          mensagem adicional
        </Typography>
        <AceitePanel
          declaracaoStatus={status}
          beneficiarios={beneficiarios}
          onClose={() => {
            fetchPendencias();
          }}
        />
      </Box>

      <Dialog
        fullWidth
        maxWidth="md"
        open={openModalSendAccept}
        onClose={handleCloseModalSendAccept}
      >
        <DialogTitle>
          Deseja enviar o aceite da auditoria para o proponente?
        </DialogTitle>
        <DialogContent>
          <Typography>
            Clique no botão confirmar para enviar o aceite da auditoria ao
            proponente.
          </Typography>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseModalSendAccept}>Cancelar</Button>
          <Button onClick={onClickSendAccept} autoFocus>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth
        maxWidth="md"
        open={openModalFinish}
        onClose={handleCloseModalFinish}
      >
        <DialogTitle>Deseja concluir o processo de auditoria?</DialogTitle>
        <DialogContent>
          <Typography sx={{ mb: 3 }}>
            Veja abaixo a lista de todas as doenças ou lesões preexistentes e
            CID-10 registrados para cada beneficiário. Confirme todas as
            informações antes de prosseguir com a conclusão do processo de
            auditoria.
          </Typography>

          <DiseasePreview beneficiarios={beneficiarios} />

          <Alert severity="warning" sx={{ mt: 3 }}>
            Certifique-se de enviar o laudo médico antes de prosseguir com a
            conclusão do processo de auditoria pois esta ação não poderá ser
            desfeita.
          </Alert>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseModalFinish}>Cancelar</Button>
          <Button variant="contained" onClick={onClickFinish} autoFocus>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>

      <Box sx={{ mt: 2, mb: 5 }}>
        <Grid display="flex" gap={1}>
          <Tooltip title={buttonGroupState.btnDone.tooltipText} placement="top">
            <span>
              <Button
                variant="outlined"
                onClick={handleOpenModalFinish}
                disabled={buttonGroupState.btnDone.disabled}
              >
                <Done />
                Concluir
              </Button>
            </span>
          </Tooltip>
        </Grid>
      </Box>
      <Box
        sx={{
          position: 'fixed',
          top: '90%',
          bottom: '50%',
          right: 25,
        }}
      >
        <Button
          variant={notificationChat === true ? 'contained' : 'outlined'}
          disabled={openChat === true}
          color="primary"
          aria-label="scroll back to top"
          onClick={handleOpenChat}
        >
          <Comment />
        </Button>
      </Box>
      <Modal
        open={openChat}
        disableScrollLock
        hideBackdrop
        sx={{
          width: 350,
          height: 450,
          top: 'auto',
          bottom: 35,
          left: 'auto',
          right: 100,
        }}
      >
        <ModalChat
          api={api}
          clientColorMessage="lightgray"
          close={handleCloseChat}
          controllerColorMessage="#4fff98"
          handleProtocol={protocolo}
          hideCloseButton={false}
          issuerAlign="auto"
          issuerMessage="auditor"
          maxWidth={250}
          receiverAlign={0}
          title={nomeTitular}
          updatePendencies={fetchChatMessages}
        />
      </Modal>
    </Box>
  );
};

export default DeclaracaoSaudeDetails;
