import { DataGrid, DataGridProps, ptBR } from '@mui/x-data-grid';

const Table: React.FC<DataGridProps> = props => (
  <DataGrid
    {...props}
    localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
  />
);

export default Table;
