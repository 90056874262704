import { Box, Grid } from '@mui/material';
import { AnimatedError } from 'components';

const RespostaRejeitada: React.FC = () => (
  <Box>
    <Grid container display="flex" justifyContent="center" alignItems="center">
      <Box sx={{ width: 'auto' }}>
        <AnimatedError>
          Não foi possível registrar a sua resposta!
        </AnimatedError>
      </Box>
    </Grid>
  </Box>
);

export default RespostaRejeitada;
