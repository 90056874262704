import { Box, Grid, Paper, Typography } from '@mui/material';
import { mask } from 'utils';

type DeclaracaoSaudeType = {
  nomeTitular: string;
  tipoPreenchimento?: string;
  dataPreenchimento?: string;
  protocolo?: string;
  telefone: string;
  email: string;
  status?: string;
  obsGeral?: string;
};

const DeclaracaoSaudeInfo: React.FC<DeclaracaoSaudeType> = ({
  nomeTitular,
  tipoPreenchimento,
  dataPreenchimento,
  protocolo,
  telefone,
  email,
  status,
  obsGeral,
}) => (
  <Paper sx={{ p: 2 }}>
    <Grid
      container
      sx={{
        '& > .MuiGrid-root > .MuiBox-root': {
          mb: 1,
          '& > .MuiTypography-root:first-of-type': { fontWeight: 600 },
        },
      }}
    >
      <Grid item xs={12} md={6}>
        <Box>
          <Typography component="span">Nº do Protocolo:</Typography>{' '}
          <Typography component="span">{protocolo}</Typography>
        </Box>
        <Box>
          <Typography component="span">Nome do Titular:</Typography>{' '}
          <Typography component="span">{nomeTitular}</Typography>
        </Box>

        <Box>
          <Typography component="span">Telefone:</Typography>{' '}
          <Typography component="span">
            {mask.formatTelefone(telefone) || 'Não cadastrado'}
          </Typography>
        </Box>
        <Box>
          <Typography component="span">E-mail:</Typography>{' '}
          <Typography component="span">{email || 'Não cadastrado'}</Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box>
          <Typography component="span">Data de Preenchimento:</Typography>{' '}
          <Typography component="span">
            {dataPreenchimento &&
              new Date(dataPreenchimento).toLocaleDateString()}
          </Typography>
        </Box>
        <Box>
          <Typography component="span">Tipo de Preenchimento:</Typography>{' '}
          <Typography component="span">{tipoPreenchimento}</Typography>
        </Box>
        <Box>
          <Typography component="span">Observações:</Typography>{' '}
          <Typography component="span">
            {obsGeral || 'Nenhuma observação'}
          </Typography>
        </Box>
        {status && (
          <Box>
            <Typography component="span">Status do Protocolo:</Typography>{' '}
            <Typography component="span">
              {status || 'Não encontrado'}
            </Typography>
          </Box>
        )}
      </Grid>
    </Grid>
  </Paper>
);

export default DeclaracaoSaudeInfo;
