import { useParams } from 'react-router-dom';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  TextField,
} from '@mui/material';
import { useAxios, useDeclaracaoSaude, useUi } from 'hooks';
import { DiseaseProps } from 'components';

type PerguntaFormProps = {
  open: boolean;
  handleClose: () => void;
  createNewDoenca: boolean;
  disease: DiseaseProps;
  idBeneficiario: string;
};

type FormValues = {
  pergunta: string;
  liberarAnexo: boolean;
};

const MIN_LENGTH_INPUT_TEXT = 10;
const MAX_LENGTH_INPUT_TEXT = 300;

const schema = yup.object().shape({
  pergunta: yup
    .string()
    .min(
      MIN_LENGTH_INPUT_TEXT,
      `O tamanho mínimo é de ${MIN_LENGTH_INPUT_TEXT} caracteres`,
    )
    .max(
      MAX_LENGTH_INPUT_TEXT,
      `O tamanho máximo é de ${MAX_LENGTH_INPUT_TEXT} caracteres`,
    )
    .required('Este campo é obrigatório'),
  liberarAnexo: yup.boolean(),
});

const SolicitarInfoForm: React.FC<PerguntaFormProps> = ({
  open,
  handleClose,
  createNewDoenca,
  disease,
  idBeneficiario,
}) => {
  const { errorMessage, successMessage } = useUi();
  const { api } = useAxios();
  const { protocolo } = useParams();
  const { updateStatus } = useDeclaracaoSaude();
  const { control, formState, handleSubmit, reset } = useForm<FormValues>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      pergunta: '',
      liberarAnexo: true,
    },
  });

  const onClose = (): void => {
    reset();
    handleClose();
  };

  const savePergunta = (
    pergunta: string,
    liberarAnexo: boolean,
    idDoenca: string,
  ): void => {
    api
      .post(`${process.env.REACT_APP_MS_AUDITORIA_URL}/perguntas`, {
        protocolo,
        pergunta,
        liberarAnexo,
        idBeneficiario,
        idDoenca,
      })
      .then(() => {
        updateStatus('EM AUDITORIA');
        successMessage('A sua pergunta foi registrada com sucesso.');
        onClose();
      })
      .catch(error => {
        if (!error.response) {
          errorMessage('Não foi possível registrar a pergunta.');
        }
        if (error.response.status === 400) {
          const { message } = error.response.data.errorMessage[0];
          errorMessage(message);
        }
      });
  };

  const onSubmit: SubmitHandler<FormValues> = ({ pergunta, liberarAnexo }) => {
    const { id, doenca, titulo, tipo, tempo, local, grau } = disease;

    if (!createNewDoenca) {
      savePergunta(pergunta, liberarAnexo, id);
      return;
    }

    api
      .post(`${process.env.REACT_APP_MS_AUDITORIA_URL}/doencas`, {
        idDoenca: id,
        titulo: doenca,
        especificacaoTitulo: titulo,
        tipo,
        tempo,
        local,
        grau,
        idBeneficiario,
        protocolo,
      })
      .then(() => {
        savePergunta(pergunta, liberarAnexo, id);
      })
      .catch(error => {
        if (!error.response) {
          errorMessage('Não foi possível registrar a pergunta.');
        }
        if (error.response.status === 400) {
          const { message } = error.response.data.errorMessage[0];
          errorMessage(message);
        }
      });
  };

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
      <DialogTitle>Solicitar informações adicionais</DialogTitle>
      <DialogContent>
        <form>
          <Grid container sx={{ '& > :not(style)': { mb: 2 } }}>
            <Grid item xs={12} sx={{ pt: 1 }}>
              <Controller
                control={control}
                name="pergunta"
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    multiline
                    rows={4}
                    inputProps={{ maxLength: MAX_LENGTH_INPUT_TEXT }}
                    variant="outlined"
                    label="Descreva sua pergunta"
                    error={!!formState.errors.pergunta}
                    helperText={`${field.value.length}/${MAX_LENGTH_INPUT_TEXT}`}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl
                component="fieldset"
                error={!!formState.errors.liberarAnexo}
              >
                <FormLabel component="legend">
                  Documentação do proponente
                </FormLabel>

                <FormControlLabel
                  label="Permitir anexo"
                  control={
                    <Controller
                      control={control}
                      name="liberarAnexo"
                      render={({ field }) => (
                        <Checkbox
                          checked={field.value}
                          onChange={field.onChange}
                        />
                      )}
                    />
                  }
                  title="Solicitar documentação do proponente"
                />
                {!!formState.errors.liberarAnexo && (
                  <FormHelperText>
                    {formState.errors.liberarAnexo.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </form>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          variant="contained"
          disabled={!formState.isValid}
        >
          Enviar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SolicitarInfoForm;
