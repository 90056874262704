import { memo, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Link,
  Paper,
  Skeleton,
  Typography,
} from '@mui/material';
import { api } from 'services';
import { token as storage, date } from 'utils';

import { usePublicLayout, useUi } from 'hooks';
import {
  Cid10DadosBeneficiario,
  Cid10Table,
  DadosMedico,
  DeclineModal,
} from 'components';
import { MedicoAuditor } from 'components/Cid10List/Cid10List';

type BeneficiarioCid10 = {
  idBeneficiario: string;
  nome: string;
  email?: string;
  cpf?: string;
  dataNascimento: string;
  cid10List: Cid10DadosBeneficiario[];
  observacao?: string;
};

type FormValues = {
  aceite: boolean;
};

type AuditorMensagem = {
  id: number;
  dataInclusao: string;
  mensagem: string;
};

const schema = yup.object().shape({
  aceite: yup.bool().oneOf([true], 'Este campo é obrigatório'),
});

const Aceite: React.FC = () => {
  const [titular, setTitular] = useState<BeneficiarioCid10>(
    {} as BeneficiarioCid10,
  );
  const [dependentes, setDependentes] = useState<BeneficiarioCid10[]>([]);
  const [linkIsValid, setLinkIsValid] = useState(false);
  const [showDeclineModal, setShowDeclineModal] = useState(false);
  const [auditorMensagens, setAuditorMensagens] = useState<AuditorMensagem[]>(
    [],
  );
  const [medicoAuditor, setMedicoAuditor] = useState<MedicoAuditor | null>(
    null,
  );

  const { hideBackdrop, showBackdrop, errorMessage } = useUi();
  const { isAuthenticated } = usePublicLayout();
  const { link } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { control, formState, handleSubmit, setValue } = useForm<FormValues>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      aceite: false,
    },
  });

  const protocolo = useMemo(
    () => searchParams.get('protocolo'),
    [searchParams],
  );

  const idTitular = useMemo(() => titular.idBeneficiario, [titular]);

  const idDependentes = useMemo(
    () => [...dependentes.map(d => d.idBeneficiario)],
    [dependentes],
  );

  const handleOpenDeclineModal = (): void => {
    setShowDeclineModal(true);
    setValue('aceite', false, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
  };

  const handleCloseDeclineModal = (): void => {
    setShowDeclineModal(false);
  };

  useEffect(() => {
    const validaLink = (): void => {
      const url = `${process.env.REACT_APP_MS_AUDITORIA_URL}/link/${link}`;
      const headers = { Authorization: `Bearer ${storage.getAccessToken()}` };

      api
        .get(url, { headers })
        .then(({ data }) => {
          if (data.isValid) {
            setLinkIsValid(true);
          } else {
            navigate('/link-invalido');
          }
        })
        .catch(() => {
          navigate('/rejeitada');
        });
    };

    if (isAuthenticated) {
      validaLink();
    }
  }, [link, navigate, isAuthenticated]);

  useEffect(() => {
    const loadCids = (): void => {
      const url = `${process.env.REACT_APP_MS_AUDITORIA_URL}/cid10-dados-beneficiario/link/${link}`;
      const headers = { Authorization: `Bearer ${storage.getAccessToken()}` };

      api
        .get(url, { headers })
        .then(({ data }) => {
          setTitular(data.titular);
          setDependentes(data.dependentes);
        })
        .catch(err => {
          if (err.response.status === 404) {
            navigate('/link-invalido');
          }
          if (err.response.status === 500) {
            navigate('/error');
          }
        });
    };

    const loadAuditorMensagem = (): void => {
      const url = `${process.env.REACT_APP_MS_AUDITORIA_URL}/auditor-mensagem`;
      const headers = { Authorization: `Bearer ${storage.getAccessToken()}` };
      const params = { protocolo };

      api.get(url, { headers, params }).then(({ data }) => {
        setAuditorMensagens(data);
      });
    };

    if (linkIsValid) {
      loadCids();
      loadAuditorMensagem();
    }
  }, [linkIsValid, link, navigate, protocolo]);

  useEffect(() => {
    if (titular?.cid10List?.length > 0) {
      setMedicoAuditor(titular.cid10List[0].medicoAuditor);
    }
  }, [titular]);

  useEffect(() => {
    if (medicoAuditor) {
      return;
    }

    const dependentesWithCid = dependentes.filter(d => d.cid10List.length > 0);
    dependentesWithCid.forEach(d => {
      const cid = d.cid10List[0];
      setMedicoAuditor(cid.medicoAuditor);
    });
  }, [dependentes, medicoAuditor]);

  const onSubmit: SubmitHandler<FormValues> = ({ aceite }) => {
    const url = `${process.env.REACT_APP_MS_AUDITORIA_URL}/aceite-auditoria/${protocolo}`;
    const headers = { Authorization: `Bearer ${storage.getAccessToken()}` };

    const promises = idDependentes.map(idBeneficiario => {
      const body = { aceite, idBeneficiario };

      return api.post(url, body, { headers });
    });

    if (titular.cid10List?.length > 0) {
      const body = { aceite, idBeneficiario: idTitular };
      promises.push(api.post(url, body, { headers }));
    }

    showBackdrop();

    return Promise.all(promises)
      .then(() => {
        navigate('/registrada');
      })
      .catch(err => {
        if (err?.response.status === 400) {
          errorMessage(err.response.data.errorMessage[0].message);
        }
        navigate('/rejeitada');
      })
      .finally(() => {
        hideBackdrop();
      });
  };

  if (!linkIsValid) {
    return null;
  }

  return (
    <Box>
      <Container maxWidth="md">
        <Box>
          <Typography
            variant="h4"
            sx={{ textAlign: 'center', textTransform: 'uppercase' }}
          >
            Análise de Declaração de Saúde
          </Typography>
        </Box>
        <Paper sx={{ p: 2, mt: 3 }}>
          <Box sx={{ mt: 3 }}>
            <Typography>
              Analisando a Declaração de Saúde do proponente:
            </Typography>

            <Grid container display="flex" justifyContent="space-between">
              {!!titular.nome && (
                <Typography>
                  <b>Nome:</b> {titular.nome}
                </Typography>
              )}
              {!!titular.dataNascimento && (
                <Typography>
                  <b>Data de Nascimento:</b> {titular.dataNascimento}
                </Typography>
              )}

              {!titular.nome && (
                <Skeleton
                  variant="text"
                  sx={{ maxWidth: 250, width: '100%' }}
                />
              )}
              {!titular.dataNascimento && (
                <Skeleton
                  variant="text"
                  sx={{ maxWidth: 250, width: '100%' }}
                />
              )}
            </Grid>
          </Box>

          <Box sx={{ mt: 3 }}>
            {titular.cid10List?.length > 0 && (
              <Cid10Table cid10List={titular.cid10List} />
            )}

            {Object.keys(titular).length === 0 && <Skeleton height={100} />}

            {!!titular.observacao && (
              <Box sx={{ marginTop: 3 }}>
                <Typography fontWeight={600}>Observações</Typography>
                <Typography>{titular.observacao}</Typography>
              </Box>
            )}
          </Box>
        </Paper>

        {dependentes.map(dependente => (
          <Paper key={dependente.idBeneficiario} sx={{ p: 2, mt: 3 }}>
            <Box sx={{ mt: 3 }}>
              <Typography>Dependente</Typography>

              <Grid container display="flex" justifyContent="space-between">
                <Typography>
                  <b>Nome:</b> {dependente.nome}
                </Typography>
                <Typography>
                  <b>Data de Nascimento:</b> {dependente.dataNascimento}
                </Typography>
              </Grid>
            </Box>

            <Box sx={{ mt: 3 }}>
              {dependente.cid10List?.length > 0 && (
                <Cid10Table cid10List={dependente.cid10List} />
              )}
              {!!dependente.observacao && (
                <Box sx={{ marginTop: 3 }}>
                  <Typography fontWeight={600}>Observações</Typography>
                  <Typography>{dependente.observacao}</Typography>
                </Box>
              )}
            </Box>
          </Paper>
        ))}

        {medicoAuditor && (
          <Paper sx={{ p: 2, mt: 3 }}>
            <Typography fontWeight={600}>Médico auditor responsável</Typography>
            <DadosMedico
              nome={medicoAuditor.nome}
              crm={medicoAuditor.crm}
              especialidade={medicoAuditor.especialidade}
            />
          </Paper>
        )}

        {auditorMensagens.length > 0 && (
          <Paper sx={{ p: 2, mt: 3 }}>
            <Typography>
              <b>Mensagem do médico auditor referente a recusa desta análise</b>
            </Typography>
            {auditorMensagens.map(({ id, mensagem, dataInclusao }) => (
              <Box key={id} sx={{ p: '10px 0' }}>
                <Typography component="span">{mensagem}</Typography>
                <br />
                <Typography fontSize={12} color="#969696">
                  {`Mensagem registrada em ${date.getDatetimeFromStringISO(
                    dataInclusao,
                  )}`}
                </Typography>
              </Box>
            ))}
          </Paper>
        )}

        <Box sx={{ mt: 10 }}>
          <Typography
            variant="h5"
            sx={{ textAlign: 'center', textTransform: 'uppercase' }}
          >
            Declaração do Proponente
          </Typography>
        </Box>

        <Paper sx={{ p: 2, mt: 3 }}>
          <Box sx={{ mt: 3 }}>
            <Typography align="justify">
              Declaro, para os devidos fins de direito que fui suficientemente
              informado de que a(as) condição(ões) supra mencionadas constitui
              (em) preexistência nos termos do artigo 1º da Resolução n.º 2 do
              CONSU publicada pelo Ministério da Saúde em 03/11/1998.
            </Typography>
          </Box>

          <Box sx={{ mt: 3 }}>
            <Typography align="justify">
              Estou informado ainda que em virtude de tais preexistências terei
              uma COBERTURA PARCIAL TEMPORÁRIA (*) de 24 (VINTE E QUATRO) meses
              a partir da data de assinatura da Proposta de Admissão.
            </Typography>
          </Box>

          <Box sx={{ mt: 3 }}>
            <Typography align="justify">
              (*) COBERTURA PARCIAL TEMPORÁRIA: &quot;É um período determinado
              de tempo em que a operadora não é obrigada a dar cobertura a esses
              - como procedimento de alta complexidade, cirurgias e leitos de
              alta tecnologia (leitos de UTI - Unidade de Tratamento Intensivo).
              Cumprindo este prazo específico o consumidor passa a usufruir de
              cobertura normal&quot;.
            </Typography>
          </Box>

          <Box sx={{ mt: 3 }}>
            <Typography fontWeight={600}>
              Procedimentos de Alta Complexidade (PAC)
            </Typography>
            <Typography align="justify">
              Para consultar a lista completa de procedimentos de alta
              complexidade - PAC, acesse o{' '}
              <Link
                href="https://www.gov.br/ans/pt-br/arquivos/assuntos/consumidor/o-que-seu-plano-deve-cobrir/Anexo_I_Rol_2021RN_465.2021_RN473_RN478_RN480_RN513.pdf"
                title="ROL de procedimentos e eventos em saúde"
                fontWeight={500}
                underline="hover"
                variant="inherit"
                target="_blank"
                rel="noopener"
                color="-moz-initial"
              >
                ROL de Procedimentos e Eventos em Saúde
              </Link>
              .
            </Typography>
          </Box>
        </Paper>

        <Paper sx={{ p: 2, mt: 3, mb: 10 }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container sx={{ '& > :not(style)': { mb: 2 } }}>
              <Grid item xs={12} sx={{ pt: 1 }}>
                <FormControl
                  component="fieldset"
                  error={!!formState.errors.aceite}
                >
                  <FormLabel component="legend">Aceite do titular</FormLabel>
                  <FormControlLabel
                    sx={{ textAlign: 'justify' }}
                    label="Por ser a expressão da verdade, autorizo o uso de minhas informações pessoais para assinatura eletrônica deste documento."
                    control={
                      <Controller
                        name="aceite"
                        control={control}
                        render={({ field }) => (
                          <Checkbox
                            checked={field.value}
                            onChange={field.onChange}
                          />
                        )}
                      />
                    }
                  />
                  <FormHelperText>
                    {formState.errors.aceite?.message || ' '}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
            <Grid display="flex" justifyContent="center" sx={{ mt: 5 }} gap={1}>
              <Button
                variant="text"
                type="button"
                color="error"
                onClick={handleOpenDeclineModal}
              >
                Recusar
              </Button>
              <Button
                variant="contained"
                type="submit"
                disabled={!formState.isValid}
              >
                Aceitar
              </Button>
            </Grid>

            <DeclineModal
              open={showDeclineModal}
              handleClose={handleCloseDeclineModal}
              idTitular={titular.cid10List?.length > 0 ? idTitular : undefined}
              idDependentes={idDependentes}
            />
          </form>
        </Paper>
      </Container>
    </Box>
  );
};

export default memo(Aceite);
