import React, { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import * as yup from 'yup';

import { api } from 'services';
import { CpfInput } from 'components';
import { usePublicLayout, useUi } from 'hooks';
import { token as storage } from 'utils';

type FormValues = {
  cpf: string;
};

const schema = yup
  .object({
    cpf: yup
      .string()
      .length(4, 'Este campo deve conter 4 dígitos')
      .required('Este campo é obrigatório'),
  })
  .required();

const CpfValidation: React.FC = () => {
  const [searchParams] = useSearchParams();
  const { setIsAuthenticated } = usePublicLayout();
  const { errorMessage } = useUi();
  const { handleSubmit, control, formState } = useForm<FormValues>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: { cpf: '' },
  });

  const protocolo = useMemo(
    () => searchParams.get('protocolo'),
    [searchParams],
  );

  const onSubmit: SubmitHandler<FormValues> = ({ cpf }) => {
    if (!protocolo) {
      errorMessage(
        'Não é possível prosseguir com a autenticação pois o link está incorreto.',
      );
      return;
    }

    api
      .post(
        `${process.env.REACT_APP_MS_AGENDA_URL}/preenchimento-formulario/valida-cpf`,
        { cpf, protocolo },
      )
      .then(({ data }) => {
        if (!data) {
          errorMessage('Erro com a resposta do servidor');
          return;
        }

        const { token } = data;

        storage.setAccessToken(token.access_token);
        storage.setRefreshToken(token.refresh_token);
        setIsAuthenticated(true);
      })
      .catch(err => {
        if (!err.response) {
          errorMessage('Problemas ao conectar no servidor');
        }
        if (err.response?.status === 404) {
          errorMessage('Os dígitos estão incorretos');
        }
        storage.setAccessToken('');
        storage.setRefreshToken('');
        setIsAuthenticated(false);
      });
  };

  return (
    <Box>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="h4">
          Bem vindo(a) a Declaração de saúde!
        </Typography>

        <Paper elevation={3} sx={{ p: 5, mt: 5, maxWidth: 650 }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container justifyContent="center">
              <Typography>
                Digite os quatro primeiros dígitos do CPF do titular
              </Typography>
            </Grid>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              sx={{ mt: 5 }}
            >
              <Controller
                name="cpf"
                control={control}
                render={({
                  field: { onBlur, onChange, ref },
                  formState: { errors },
                }) => (
                  <CpfInput
                    onBlur={onBlur}
                    onChange={onChange}
                    inputRef={ref}
                    errorMessage={errors.cpf?.message}
                  />
                )}
              />
            </Grid>
            <Grid
              container
              item
              justifyContent="center"
              alignItems="center"
              sx={{ mt: 5 }}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={!formState.isValid}
              >
                continuar
              </Button>
            </Grid>
          </form>
        </Paper>
      </Grid>
    </Box>
  );
};

export default CpfValidation;
