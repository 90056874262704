import { useMemo, useState } from 'react';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Tooltip,
  Typography,
} from '@mui/material';

import {
  PendenciaProps,
  RespostaProps,
  SolicitarInfoImc,
  PendenciaItem,
} from 'components';

const base64ToArrayBuffer = (base64: string): Uint8Array => {
  const binaryString = window.atob(base64);
  const binaryLen = binaryString.length;
  const bytes = new Uint8Array(binaryLen);

  for (let i = 0; i < binaryLen; i += 1) {
    const ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }

  return bytes;
};

const handleOpenPDF = (base64: string): void => {
  const arrayBuffer = base64ToArrayBuffer(base64);
  const file = new Blob([arrayBuffer], { type: 'application/pdf' });
  const fileURL = URL.createObjectURL(file);

  window.open(fileURL);
};

export type DiseaseProps = {
  id: string;
  doenca: string;
  titulo: string;
  tipo: string;
  tempo: string;
  local: string;
  grau: string;
  especificacaoManual: string;
};

export type DoencaProps = {
  id: number;
  idDoenca: string;
  especificacaoTitulo: string;
  titulo: string;
  tipo?: string;
  tempo: string;
  local?: string;
  grau?: string;
  idBeneficiario: string;
  protocolo: string;
};

type PerguntaStatusProps = {
  id: number;
  status?: string;
};

export type PerguntaProps = {
  id: number;
  pergunta: string;
  dataInclusao: string;
  idProfissional: number;
  liberarAnexo: boolean;
  status: PerguntaStatusProps;
};

type ImcInfoProps = {
  handleOpen: boolean;
  handleClose: () => void;
  idBeneficiario: string;
  peso: string;
  altura: string;
  imc: string;
  disabled?: boolean;
  pendencies: PendenciaProps[];
  updatePendencies: () => void;
  unviewedPendencies: RespostaProps[];
};

const ImcInfo: React.FC<ImcInfoProps> = ({
  handleOpen,
  handleClose,
  idBeneficiario,
  peso,
  altura,
  imc,
  disabled,
  pendencies,
  updatePendencies,
}) => {
  const [openForm, setOpenForm] = useState(false);

  const disableSolicitarButton = useMemo(() => {
    const lastItem = [...pendencies].pop();

    return (
      lastItem?.pergunta.id !== 0 &&
      lastItem?.doenca.idBeneficiario === idBeneficiario &&
      lastItem?.resposta.id === 0
    );
  }, [idBeneficiario, pendencies]);

  const perguntaIsEmpty = useMemo(() => {
    const lastItem = [...pendencies].pop();

    if (lastItem === undefined) {
      return true;
    }

    return lastItem?.pergunta.id === 0 || lastItem?.doenca.id === 0;
  }, [pendencies]);

  const handleOpenForm = (): void => {
    setOpenForm(true);
  };

  const handleCloseForm = (): void => {
    setOpenForm(false);
    updatePendencies();
  };

  return (
    <Dialog fullWidth maxWidth="md" open={handleOpen} onClose={handleClose}>
      <DialogTitle>Detalhes sobre IMC</DialogTitle>
      <DialogContent sx={{ marginTop: 3 }}>
        {perguntaIsEmpty ? (
          <Typography>Nenhuma informação solicitada.</Typography>
        ) : (
          <Grid container>
            {pendencies.map(pendency =>
              pendency.pergunta.id === 0 ? null : (
                <Grid
                  key={JSON.stringify(pendency)}
                  item
                  sm={12}
                  md={12}
                  sx={{ mb: 2, '&:last-of-type': { mb: 0 } }}
                >
                  {pendency.doenca.idBeneficiario === idBeneficiario && (
                    <Box>
                      <PendenciaItem
                        pergunta={pendency.pergunta}
                        handleClose={updatePendencies}
                        disabled={disabled}
                      />

                      {!!pendency.resposta?.resposta && (
                        <Typography sx={{ mb: 1 }}>
                          Resposta do titular: {pendency.resposta.resposta}
                        </Typography>
                      )}
                      {!pendency.resposta?.resposta &&
                        pendency.pergunta.status.id === 1 && (
                          <Chip label="Aguardando envio" />
                        )}
                      {!pendency.resposta?.resposta &&
                        pendency.pergunta.status.id === 2 && (
                          <Chip label="Aguardando resposta do titular" />
                        )}

                      {pendency.documento?.documento && (
                        <Tooltip
                          title="Ver documento enviado pelo proponente"
                          placement="top"
                        >
                          <Button
                            type="button"
                            size="small"
                            variant="text"
                            onClick={() =>
                              pendency.documento?.documento &&
                              handleOpenPDF(pendency.documento.documento)
                            }
                          >
                            Ver documento
                          </Button>
                        </Tooltip>
                      )}
                    </Box>
                  )}
                </Grid>
              )
            )}
          </Grid>
        )}
      </DialogContent>
      {!disabled && (
        <DialogContent>
          <Button
            variant="outlined"
            onClick={handleOpenForm}
            disabled={disableSolicitarButton}
          >
            Solicitar Informações
          </Button>
          <SolicitarInfoImc
            open={openForm}
            handleClose={handleCloseForm}
            idBeneficiario={idBeneficiario}
            peso={peso}
            altura={altura}
            imc={imc}
            idImc="imc_alterado"
            createNewDoenca={pendencies.length === 0}
          />
        </DialogContent>
      )}
      <DialogContent sx={{ marginLeft: 'auto' }}>
        <Button variant="text" onClick={handleClose}>
          Fechar
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default ImcInfo;
