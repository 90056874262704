import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useState,
} from 'react';

import { token as storage } from 'utils';

export type PublicLayout = {
  isAuthenticated: boolean;
  setIsAuthenticated: Dispatch<SetStateAction<boolean>>;
  logout: () => void;
  protocolo: string;
  setProtocolo: Dispatch<SetStateAction<string>>;
};

const PublicLayoutContext = createContext<PublicLayout>({} as PublicLayout);

const PublicLayoutProvider: React.FC = ({ children }) => {
  const [protocolo, setProtocolo] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const logout = (): void => {
    storage.setAccessToken('');
    storage.setRefreshToken('');
    setIsAuthenticated(false);
  };

  return (
    <PublicLayoutContext.Provider
      value={{
        isAuthenticated,
        setIsAuthenticated,
        logout,
        protocolo,
        setProtocolo,
      }}
    >
      {children}
    </PublicLayoutContext.Provider>
  );
};

export { PublicLayoutContext, PublicLayoutProvider };
