import { Box, Grid } from '@mui/material';
import { AnimatedError } from 'components';

const Forbidden: React.FC = () => (
  <Box>
    <Grid container display="flex" justifyContent="center" alignItems="center">
      <Box>
        <AnimatedError>Acesso não autorizado.</AnimatedError>
      </Box>
    </Grid>
  </Box>
);

export default Forbidden;
