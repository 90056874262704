import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Skeleton,
  Tooltip,
  Typography,
} from '@mui/material';

import { useAxios, useDeclaracaoSaude, useUi } from 'hooks';
import { BeneficiarioType } from 'context';
import { DiseasePreview, ResendCid10 } from 'components';
import { Cid10DadosBeneficiario } from 'components/Cid10List/Cid10List';

type BeneficiarioCid10 = {
  idBeneficiario: string;
  nome: string;
  email?: string;
  cpf?: string;
  dataNascimento: string;
  cid10List: Cid10DadosBeneficiario[];
  observacao?: string;
};

type AceitePanelProps = {
  declaracaoStatus?: string;
  onClose: () => void;
  beneficiarios: BeneficiarioType[];
};

const AceitePanel: React.FC<AceitePanelProps> = ({
  onClose,
  beneficiarios,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [statusRequest, setStatusRequest] = useState<
    'TODO' | 'IN_PROGRESS' | 'DONE'
  >('TODO');
  const [titular, setTitular] = useState<BeneficiarioCid10>(
    {} as BeneficiarioCid10
  );
  const [dependentes, setDependentes] = useState<BeneficiarioCid10[]>([]);

  const { api } = useAxios();
  const navigate = useNavigate();
  const {
    showBackdrop,
    hideBackdrop,
    successMessage,
    errorMessage,
    fetchingCid10,
    loadedCid10,
  } = useUi();
  const { protocolo } = useParams();
  const { status, updateStatus } = useDeclaracaoSaude();

  const isEmpty = useMemo(
    () => titular?.cid10List?.length === 0 && dependentes.length === 0,
    [titular, dependentes]
  );

  const showResendButton = useMemo(
    () => isEmpty && status === 'RECUSADO',
    [isEmpty, status]
  );

  const showDefaultText = useMemo(
    () => isEmpty && statusRequest === 'DONE' && !showResendButton,
    // || status === 'PENDENTE DE ACEITE',
    [isEmpty, statusRequest, showResendButton, status]
  );

  const handleOpenModal = (): void => {
    setShowModal(true);
  };

  const handleCloseModal = (): void => {
    setShowModal(false);
    onClose();
  };

  const fetchCid10 = useCallback((): void => {
    setStatusRequest('IN_PROGRESS');
    api
      .get(
        `${process.env.REACT_APP_MS_AUDITORIA_URL}/cid10-dados-beneficiario/${protocolo}`
      )
      .then(({ data }) => {
        setTitular(data.titular);
        setDependentes(data.dependentes);
      })
      .catch(err => {
        if (err?.response?.status === 400) {
          errorMessage(err.response.data.errorMessage[0].message);
          return;
        }
        errorMessage(
          'Não foi possível carregar os dados do(s) beneficiário(s).'
        );
      })
      .finally(() => {
        setStatusRequest('DONE');
        loadedCid10();
      });
  }, [api, errorMessage, loadedCid10, protocolo]);

  const sendItems = (): void => {
    showBackdrop();

    api
      .post(
        `${process.env.REACT_APP_MS_AUDITORIA_URL}/notificacao/enviar-cid10/${protocolo}`
      )
      .then(() => {
        // updateStatus('PENDENTE DE ACEITE');
        successMessage('Os registros foram enviados ao titular com sucesso');
        handleCloseModal();
        navigate('/');
      })
      .catch(err => {
        if (err?.response?.status === 400) {
          errorMessage(err.response.data.errorMessage[0].message);
          return;
        }
        errorMessage('Não foi possível enviar para o nosso servidor');
      })
      .finally(() => {
        hideBackdrop();
      });
  };

  useEffect(() => {
    if (fetchingCid10) {
      fetchCid10();
    }
  }, [fetchCid10, fetchingCid10]);

  useEffect(() => {
    fetchCid10();
  }, [fetchCid10]);

  return (
    <Paper sx={{ p: 2, pt: 0, mt: 3 }}>
      {statusRequest !== 'DONE' && (
        <Skeleton variant="text" sx={{ maxWidth: 315, width: '100%', pt: 2 }} />
      )}
      {showDefaultText && (
        <Typography sx={{ pt: 2 }}>
          Nenhum beneficiário com CID10 pendente para envio.
        </Typography>
      )}

      {showResendButton && <ResendCid10 />}

      {!showDefaultText && !showResendButton && (
        <Grid container>
          <Box>
            <List>
              {titular?.cid10List?.length > 0 && (
                <ListItem disablePadding>
                  <Tooltip placement="left" title="Código do CID10">
                    <ListItemText
                      primary={titular.nome}
                      secondary={titular.cid10List
                        .map(({ cid10 }) => cid10.codigo)
                        .join(', ')}
                    />
                  </Tooltip>
                </ListItem>
              )}
              {dependentes.map(({ idBeneficiario, nome, cid10List }) => (
                <ListItem key={idBeneficiario} disablePadding>
                  <Tooltip placement="left" title="Código do CID10">
                    <ListItemText
                      primary={nome}
                      secondary={cid10List
                        .map(({ cid10 }) => cid10.codigo)
                        .join(', ')}
                    />
                  </Tooltip>
                </ListItem>
              ))}
            </List>
          </Box>

          <Box sx={{ marginTop: 1, width: '100%' }}>
            <Tooltip
              title="Envie todos os registros pendentes"
              placement="right"
            >
              <Button
                variant="contained"
                onClick={handleOpenModal}
                size="small"
              >
                Enviar ao titular
              </Button>
            </Tooltip>
            <Typography
              align="justify"
              fontSize={12}
              fontWeight={200}
              sx={{ mt: 1 }}
            >
              *Após enviar os laudos não será mais possível alterar, excluir ou
              adicionar outro CID10 a qualquer beneficiário.
            </Typography>
          </Box>

          <Dialog
            fullWidth
            maxWidth="md"
            open={showModal}
            onClose={handleCloseModal}
          >
            <DialogTitle>Deseja enviar todos os laudos médicos?</DialogTitle>
            <DialogContent>
              <Typography align="justify" sx={{ mb: 3 }}>
                Veja abaixo a lista de todas as doenças ou lesões preexistentes
                e CID-10 registrados para cada beneficiário. Confirme todas as
                informações antes de prosseguir com o envio do laudo médico.
              </Typography>

              <DiseasePreview beneficiarios={beneficiarios} />

              <Alert severity="warning" sx={{ mt: 3 }}>
                Certifique-se de inserir todos os CID-10 necessários antes de
                enviar o laudo médico, pois esta ação não poderá ser desfeita.
              </Alert>
            </DialogContent>

            <DialogActions>
              <Button onClick={handleCloseModal}>Cancelar</Button>
              <Button variant="contained" onClick={sendItems} autoFocus>
                Confirmar
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      )}
    </Paper>
  );
};

export default AceitePanel;
