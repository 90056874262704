import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { Cid10DadosBeneficiario } from 'components';

type Cid10TableProps = {
  cid10List: Cid10DadosBeneficiario[];
};

const Cid10Table: React.FC<Cid10TableProps> = ({ cid10List }) => (
  <Box>
    <Typography>
      Tem as seguintes condições que constituem doenças ou lesões preexistente:
    </Typography>
    <TableContainer sx={{ marginTop: 2 }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>CID</TableCell>
            <TableCell>Descrição</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {cid10List.map(({ cid10: { codigo, nome } }) => (
            <TableRow key={codigo}>
              <TableCell>{codigo}</TableCell>
              <TableCell>{nome}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </Box>
);

export default Cid10Table;
