import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Skeleton,
  Typography,
} from '@mui/material';

import { useAxios } from 'hooks';
import { BeneficiarioType } from 'context';
import { Cid10DadosBeneficiario } from 'components/Cid10List/Cid10List';

type BeneficiarioCid10 = {
  idBeneficiario: string;
  nome: string;
  email?: string;
  cpf?: string;
  dataNascimento: string;
  cid10List: Cid10DadosBeneficiario[];
  observacao?: string;
};

type Cids = {
  [key: string]: BeneficiarioCid10;
};

type DiseasePreviewProps = {
  beneficiarios: BeneficiarioType[];
};

const DiseasePreview: React.FC<DiseasePreviewProps> = ({ beneficiarios }) => {
  const [loadingCid10, setLoadingCid10] = useState(false);
  const [cids, setCids] = useState<Cids>({} as Cids);

  const { protocolo } = useParams();
  const { api } = useAxios();

  const fetchCid10 = useCallback(
    (p: string): void => {
      const url = `${process.env.REACT_APP_MS_AUDITORIA_URL}/cid10-dados-beneficiario/${p}`;

      setLoadingCid10(true);

      api.get(url).then(({ data }) => {
        const { titular, dependentes } = data;
        const newCids = {} as Cids;

        if (titular?.cid10List?.length) {
          newCids[titular.idBeneficiario] = titular;
        }
        if (dependentes?.length) {
          (dependentes as BeneficiarioCid10[]).forEach(dependente => {
            if (dependente.cid10List?.length) {
              newCids[dependente.idBeneficiario] = dependente;
            }
          });
        }

        setCids(newCids);
        setLoadingCid10(false);
      });
    },
    [api],
  );

  useEffect(() => {
    if (protocolo) {
      fetchCid10(protocolo);
    }
  }, [fetchCid10, protocolo]);

  const getCidList = (id: string): Cid10DadosBeneficiario[] =>
    cids[id]?.cid10List || [];

  const renderDLP = (
    id: string,
    primary: string,
    secondary: string,
  ): JSX.Element => (
    <ListItem key={id}>
      <ListItemText primary={primary} secondary={secondary} />
    </ListItem>
  );

  const renderHeader = (text: string): JSX.Element => (
    <ListSubheader>
      <u>{text}</u>
    </ListSubheader>
  );

  const renderEmptyList = (header: string, text: string): JSX.Element => (
    <List dense subheader={renderHeader(header)}>
      <ListItem>
        <ListItemText primary={text} />
      </ListItem>
    </List>
  );

  return (
    <>
      {beneficiarios.map(({ idBeneficiario, nome, perguntas }) => {
        const answeredQuestion = perguntas.filter(p => p.possui === true);

        return (
          <Box key={idBeneficiario}>
            <Divider sx={{ mb: 2 }} />
            <Typography fontWeight={600} sx={{ pl: 2 }}>
              {nome}
            </Typography>
            <Grid container>
              <Grid item xs={12} md={6}>
                {answeredQuestion.length === 0 &&
                  renderEmptyList(
                    'Doenças ou Lesões Preexistentes',
                    'Nenhuma DLP registrada pelo beneficiário',
                  )}
                {answeredQuestion.length > 0 && (
                  <List
                    dense
                    subheader={renderHeader('Doenças ou Lesões Preexistentes')}
                  >
                    {answeredQuestion.map(({ id, especificacao, titulo }) =>
                      renderDLP(
                        `${id}_${nome}`,
                        especificacao[0]?.titulo,
                        titulo,
                      ),
                    )}
                  </List>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                {loadingCid10 && <Skeleton />}
                {!loadingCid10 &&
                  getCidList(idBeneficiario).length === 0 &&
                  renderEmptyList(
                    'Classificação Internacional de Doenças',
                    'Nenhum CID-10 registrado para o beneficiário',
                  )}
                {getCidList(idBeneficiario).length > 0 && (
                  <List
                    dense
                    subheader={renderHeader(
                      'Classificação Internacional de Doenças',
                    )}
                  >
                    {getCidList(idBeneficiario).map(({ cid10 }) => (
                      <ListItem key={`${idBeneficiario}_${cid10.codigo}`}>
                        <ListItemText
                          primary={cid10.nome}
                          secondary={cid10.codigo}
                        />
                      </ListItem>
                    ))}
                  </List>
                )}
              </Grid>
            </Grid>
          </Box>
        );
      })}
    </>
  );
};

export default DiseasePreview;
