import { useParams } from 'react-router-dom';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  TextField,
} from '@mui/material';
import { useAxios } from 'hooks';
import React from 'react';

type PerguntaFormProps = {
  open: boolean;
  handleClose: () => void;
};

type FormValues = {
  pergunta: string;
  liberarAnexo: boolean;
};

const schema = yup.object().shape({
  pergunta: yup
    .string()
    .min(10, 'O tamanho mínimo e de 10 caracteres')
    .max(250, 'O tamanho máximo e de 250 caracteres')
    .required('Este campo e obrigatorio'),
  liberarAnexo: yup.boolean(),
});

const PerguntaForm: React.FC<PerguntaFormProps> = ({ open, handleClose }) => {
  const { api } = useAxios();
  const { protocolo } = useParams();
  const { control, formState, handleSubmit } = useForm<FormValues>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      pergunta: '',
      liberarAnexo: false,
    },
  });

  const onSubmit: SubmitHandler<FormValues> = ({ pergunta, liberarAnexo }) => {
    const url = `${process.env.REACT_APP_MS_AUDITORIA_URL}/perguntas`;
    const body = { protocolo, pergunta, liberarAnexo };

    api.post(url, body).then(() => {
      handleClose();
    });
  };
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Solicitar Informacoes</DialogTitle>
      <DialogContent>
        <form>
          <Grid container sx={{ '& > :not(style)': { mb: 2 } }}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="pergunta"
                render={({ field }) => (
                  <TextField
                    {...field}
                    multiline
                    variant="standard"
                    label="Pergunta"
                    error={!!formState.errors.pergunta}
                    helperText={formState?.errors?.pergunta?.message}
                    fullWidth
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl
                component="fieldset"
                error={!!formState.errors.liberarAnexo}
              >
                <FormLabel component="legend">
                  Solicitar documento do proponente?
                </FormLabel>
                <Controller
                  control={control}
                  name="liberarAnexo"
                  render={({ field }) => (
                    <FormControlLabel
                      {...field}
                      label="Sim"
                      control={<Checkbox />}
                    />
                  )}
                />
                {!!formState.errors.liberarAnexo && (
                  <FormHelperText>
                    {formState.errors.liberarAnexo.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </form>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>Cancelar</Button>
        <Button onClick={handleSubmit(onSubmit)} variant="contained">
          Enviar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PerguntaForm;
