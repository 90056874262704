import React, { createContext, useEffect, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { useLocation, useNavigate } from 'react-router-dom';

export type Layout = {
  isAuthorized: boolean;
  openMenu: boolean;
  handleOpenMenu: () => void;
  handleCloseMenu: () => void;
};

const PrivateLayoutContext = createContext<Layout>({} as Layout);

const PrivateLayoutProvider: React.FC = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { initialized, keycloak } = useKeycloak();
  const [openMenu, setOpenMenu] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(false);

  const authorizedAcces = ['auditor'];
  const notAuthorizedAcces = [
    'administrador_operadora',
    'administrador',
    'operadora',
    'suporte',
  ];
  const userAcces = keycloak?.tokenParsed?.realm_access?.roles;

  useEffect(() => {
    if (!initialized) {
      setIsAuthenticated(false);
      return;
    }
    if (!keycloak.authenticated) {
      keycloak.login();
      return;
    }

    setIsAuthenticated(true);

    if (
      userAcces?.some(roles => authorizedAcces.includes(roles)) &&
      !userAcces?.some(roles => notAuthorizedAcces.includes(roles))
    ) {
      if (location.pathname === '/naoautorizado') {
        navigate('/');
      }
      setIsAuthorized(true);
    } else {
      setIsAuthorized(false);
      navigate('/naoautorizado');
    }

    console.log('KeycloakProvider loaded');
  }, [initialized, keycloak, isAuthorized]);

  const handleOpenMenu = (): void => {
    setOpenMenu(true);
  };

  const handleCloseMenu = (): void => {
    setOpenMenu(false);
  };

  if (!isAuthenticated) {
    console.log('Loading KeycloakProvider');
    return <div>Loading...</div>;
  }

  return (
    <PrivateLayoutContext.Provider
      value={{
        isAuthorized,
        openMenu,
        handleOpenMenu,
        handleCloseMenu,
      }}
    >
      {children}
    </PrivateLayoutContext.Provider>
  );
};

export { PrivateLayoutContext, PrivateLayoutProvider };
