import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Tab, Tabs, Tooltip } from '@mui/material';
import {
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
} from '@mui/x-data-grid';

import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';

import { BadgeTheme, Table, TabPanel } from 'components';

import { StatusType, STATUS } from 'context';

import { useAxios, useDeclaracaoSaude, useUi } from 'hooks';
import { date } from 'utils';

type ResponseType = {
  protocolo: string;
  nomeTitular: string;
  totalDependentes: string;
  dataPreenchimento: string;
  dataExpiracaoLink: string;
  tipoPreenchimento: string;
  idOperadora: number;
  novaMensagem: boolean | null;
};

type RowType = {
  id: string;
  protocolo: string;
  nomeTitular: string;
  totalDependentes: string;
  dataPreenchimento: string;
  dataExpiracaoLink: string;
  diasAberto: number;
  tipoPreenchimento: string;
  idOperadora: number;
  novaMensagem: number;
  rowBackground: 'danger' | 'default' | 'warning';
};

type Protocols = {
  [key in StatusType]?: ResponseType[];
};

type A11yProps = {
  id: string;
  'aria-controls': string;
};

const PROTOCOLO_STATUS_LABEL: { [key in StatusType]: string } = {
  'PENDENTE DE AUDITORIA': 'Pendente de Auditoria',
  'EM AUDITORIA': 'Em Auditoria',
  'RESPOSTA EM ANALISE': 'Resposta em Análise',
  CONCLUIDO: 'Concluído',
  RECUSADO: 'Recusado',
  CANCELADO: 'Cancelado',
} as const;

const VISIBLE_PROTOCOL_STATUS = STATUS.filter(
  p => !['CANCELADO', 'CONCLUIDO'].includes(p)
);

const a11yProps = (index: number): A11yProps => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
});

const DeclaracaoSaude: React.FC = () => {
  const navigate = useNavigate();
  const { api } = useAxios();
  const { errorMessage, hideBackdrop, showBackdrop } = useUi();
  const { clearDs, setDs } = useDeclaracaoSaude();

  const [currentTab, setCurrentTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [protocols, setProtocols] = useState<Protocols>({} as Protocols);

  useEffect(() => {
    clearDs();
  }, [clearDs]);

  useEffect(() => {
    if (loading) {
      showBackdrop();
    } else {
      hideBackdrop();
    }

    return () => {
      hideBackdrop();
    };
  }, [loading, hideBackdrop, showBackdrop]);

  useEffect(() => {
    const fetchFormulariosBasic = (): void => {
      const url = `${process.env.REACT_APP_MS_AUDITORIA_URL}/protocolo/v3/protocolo-status`;

      setLoading(true);

      api
        .get(url)
        .then(({ data }) => {
          setProtocols(data);
        })
        .catch(() => {
          navigate('/error');
        })
        .finally(() => {
          setLoading(false);
        });
    };
    fetchFormulariosBasic();
  }, [api, navigate]);

  const getRows = (f: ResponseType): RowType => {
    const {
      protocolo,
      nomeTitular,
      totalDependentes,
      dataPreenchimento,
      dataExpiracaoLink,
      tipoPreenchimento,
      idOperadora,
      novaMensagem,
    } = f;

    const expirationDate = dataExpiracaoLink
      ? new Date(dataExpiracaoLink).toLocaleDateString()
      : '';
    const expirationDiff = new Date(dataExpiracaoLink).getTime();
    const formDate = new Date(f.dataPreenchimento).getTime();
    const actualDay = new Date().getTime();
    const difference = Math.abs(actualDay - formDate);
    const daysLeft = Math.ceil(difference / (1000 * 60 * 60 * 24));

    const notification =
      novaMensagem === true
        ? 0
        : novaMensagem === false
        ? 1
        : novaMensagem === null
        ? 2
        : 3;

    return {
      id: protocolo,
      novaMensagem: notification,
      protocolo,
      nomeTitular,
      totalDependentes,
      dataPreenchimento,
      dataExpiracaoLink:
        expirationDiff < actualDay
          ? `${expirationDate} (Expirado)`
          : `${expirationDate} (Valido)` || '',
      diasAberto: daysLeft,
      tipoPreenchimento,
      idOperadora,
      rowBackground:
        daysLeft >= 24 && daysLeft <= 35
          ? 'warning'
          : daysLeft >= 35
          ? 'danger'
          : 'default',
    };
  };

  const columns: GridColDef[] = [
    {
      field: 'novaMensagem',
      type: 'action',
      renderCell: (params: GridRenderCellParams) =>
        params.value === 0 ? (
          <Tooltip title="Novas mensagens">
            <ChatBubbleIcon fontSize="small" color="primary" />
          </Tooltip>
        ) : params.value === 1 ? (
          <Tooltip title="Sem novas mensagens">
            <ChatBubbleOutlineIcon fontSize="small" color="disabled" />
          </Tooltip>
        ) : params.value === 2 ? (
          <Tooltip title="Chat não utilizado">
            <ChatBubbleOutlineIcon
              fontSize="small"
              sx={{ color: 'transparent' }}
            />
          </Tooltip>
        ) : (
          <Tooltip title="Chat não utilizado">
            <ChatBubbleOutlineIcon
              fontSize="small"
              sx={{ color: 'transparent' }}
            />
          </Tooltip>
        ),
      headerName: '',
      align: 'center',
      headerAlign: 'center',
      width: 60,
      disableColumnMenu: true,
    },
    { field: 'protocolo', headerName: 'Protocolo', flex: 1 },
    { field: 'nomeTitular', headerName: 'Titular', flex: 1 },
    {
      field: 'totalDependentes',
      headerName: 'Total de Dependentes',
      flex: 1,
      align: 'center',
    },
    {
      field: 'dataPreenchimento',
      headerName: 'Data de Preenchimento',
      flex: 1,
      valueFormatter: (params: { value: string }) =>
        new Date(params.value as string).toLocaleDateString(),
      align: 'center',
    },
    {
      field: 'diasAberto',
      headerName: 'Dias em Aberto',
      width: 130,
      align: 'center',
    },
    {
      field: 'dataExpiracaoLink',
      headerName: 'Data de Expiração',
      flex: 1,
      align: 'center',
      hide:
        currentTab === 0 ||
        currentTab === 1 ||
        currentTab === 3 ||
        currentTab === 5,
    },
    {
      field: 'tipoPreenchimento',
      headerName: 'Tipo de Preenchimento',
      flex: 1,
    },
  ];

  const getRowsByStatus = (status: StatusType): RowType[] =>
    protocols[status]?.map(getRows) ?? [];

  const onRowClick = (gridRow: GridRowParams, status: StatusType): void => {
    const f = protocols[status]?.find(
      ({ protocolo }) => protocolo === gridRow.id
    );

    if (!f) {
      errorMessage('Não localizamos os dados desse formulário.');
      return;
    }

    const fetchFormulariosDetails = (): void => {
      const url = `${process.env.REACT_APP_MS_AGENDA_URL}/formulario/operadora/${f.idOperadora}/protocolo/${gridRow.id}`;

      setLoading(true);
      api
        .get(url)
        .then(({ data }) => {
          setDs({
            protocolo: f.protocolo,
            formulario: JSON.parse(data.formulario),
            tipoPreenchimento: gridRow.row.tipoPreenchimento,
            status,
            operadora: f.idOperadora,
          });
          navigate(`/declaracao-de-saude/${gridRow.id}`);
        })
        .catch(() => {
          navigate('/error');
        })
        .finally(() => {
          setLoading(false);
        });
    };
    fetchFormulariosDetails();
  };

  const handleChangeTab = (
    _event: React.SyntheticEvent,
    tabIndex: number
  ): void => {
    setCurrentTab(tabIndex);
  };

  const renderLabelWithBadge = (status: StatusType): React.ReactElement => (
    <BadgeTheme color="primary" badgeContent={getRowsByStatus(status).length}>
      {PROTOCOLO_STATUS_LABEL[status]}
    </BadgeTheme>
  );

  return (
    <Box>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={currentTab} onChange={handleChangeTab} centered>
          {VISIBLE_PROTOCOL_STATUS.map((status, index) => (
            <Tab
              label={renderLabelWithBadge(status)}
              {...a11yProps(index)}
              key={`${status}_${date.getCurrentMillis()}`}
              wrapped
            />
          ))}
        </Tabs>
      </Box>
      {VISIBLE_PROTOCOL_STATUS.map((status, index) => (
        <TabPanel
          value={currentTab}
          index={index}
          key={`${status}_${date.getCurrentMillis()}`}
        >
          <Box sx={{ minHeight: 165, width: '100%' }}>
            <Table
              initialState={{
                pagination: { pageSize: 10 },
                sorting: {
                  sortModel: [{ field: 'dataPreenchimento', sort: 'asc' }],
                },
              }}
              rows={getRowsByStatus(status)}
              columns={columns}
              rowsPerPageOptions={[5, 10]}
              autoHeight
              onRowClick={gridRow => onRowClick(gridRow, status)}
              sx={{
                '& .MuiDataGrid-row:hover': { cursor: 'pointer' },
                '& .row-background-danger': {
                  backgroundColor: '#ffd8d8',
                },
                '& .row-background-warning': {
                  backgroundColor: '#fff6d9',
                },
              }}
              getRowClassName={params =>
                `row-background-${params.row.rowBackground}`
              }
            />
          </Box>
        </TabPanel>
      ))}
    </Box>
  );
};

export default DeclaracaoSaude;
