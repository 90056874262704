import Lottie, { Options } from 'react-lottie';
import { CheckAnimation } from 'assets/lotties';
import { Box, Typography } from '@mui/material';

const defaultLottieOptions: Options = {
  loop: true,
  autoplay: true,
  animationData: CheckAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const AnimatedCheck: React.FC = ({ children }) => (
  <Box sx={{ width: '100%', textAlign: 'center' }}>
    <Typography variant="h5">{children}</Typography>
    <Lottie options={defaultLottieOptions} />
  </Box>
);

export default AnimatedCheck;
