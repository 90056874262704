import { useNavigate, useParams } from 'react-router-dom';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { Box, Button, TextField } from '@mui/material';

import { useAxios, useDeclaracaoSaude, useUi } from 'hooks';

type FormValues = {
  mensagem: string;
};

const MIN_LENGTH_INPUT_TEXT = 10;
const MAX_LENGTH_INPUT_TEXT = 300;

const schema = yup.object().shape({
  mensagem: yup
    .string()
    .min(
      MIN_LENGTH_INPUT_TEXT,
      `O tamanho mínimo é de ${MIN_LENGTH_INPUT_TEXT} caracteres`
    )
    .max(
      MAX_LENGTH_INPUT_TEXT,
      `O tamanho máximo é de ${MAX_LENGTH_INPUT_TEXT} caracteres`
    )
    .required(),
});

const ResendCid10: React.FC = () => {
  const { protocolo } = useParams();
  const { api } = useAxios();
  const navigate = useNavigate();
  const { errorMessage, successMessage } = useUi();
  const { updateStatus } = useDeclaracaoSaude();
  const { control, formState, handleSubmit } = useForm<FormValues>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      mensagem: '',
    },
  });

  const resend = (): Promise<void> =>
    api
      .post(
        `${process.env.REACT_APP_MS_AUDITORIA_URL}/notificacao/enviar-cid10/${protocolo}`
      )
      .then(() => {
        // updateStatus('PENDENTE DE ACEITE');
        successMessage('O(s) laudo(s) foram reenviados para o titular!');
        navigate('/');
      })
      .catch(err => {
        if (err?.response?.status === 400) {
          errorMessage(err.response.data.errorMessage[0].message);
          return;
        }
        errorMessage('Não foi possível enviar para o nosso servidor');
      });

  const saveMensagem = (mensagem: string): Promise<void> =>
    api
      .post(`${process.env.REACT_APP_MS_AUDITORIA_URL}/auditor-mensagem`, {
        mensagem,
        protocolo,
      })
      .then(() => {
        successMessage('Mensagem registrada com sucesso!');
        return resend();
      })
      .catch(err => {
        if (err?.response?.status === 400) {
          errorMessage(err.response.data.errorMessage[0].message);
          return;
        }
        errorMessage('Erro ao tentar salvar a mensagem!');
      });

  const onSubmit: SubmitHandler<FormValues> = ({ mensagem }) => {
    saveMensagem(mensagem);
  };

  return (
    <Box sx={{ mt: 2 }} component="form" onSubmit={handleSubmit(onSubmit)}>
      <Controller
        control={control}
        name="mensagem"
        render={({ field }) => (
          <TextField
            {...field}
            fullWidth
            multiline
            variant="standard"
            inputProps={{ maxLength: MAX_LENGTH_INPUT_TEXT }}
            label="Reenviar laudo médico com esclarecimento sobre as dúvidas do beneficiário"
            error={!!formState.errors?.mensagem}
            helperText={`${field.value.length}/${MAX_LENGTH_INPUT_TEXT}`}
            margin="normal"
          />
        )}
      />
      <Button variant="contained" type="submit" disabled={!formState.isValid}>
        Reenviar Laudo Médico
      </Button>
    </Box>
  );
};

export default ResendCid10;
