import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material';

import { PendenciaProps, PendenciaItem } from 'components';
import { useAxios, useDeclaracaoSaude, useUi } from 'hooks';

const Pendencias: React.FC<{
  pendencias: PendenciaProps[];
  onClose: () => void;
}> = ({ pendencias, onClose }) => {
  const [showModal, setShowModal] = useState(false);
  const { api } = useAxios();
  const navigate = useNavigate();
  const { showBackdrop, hideBackdrop, successMessage, errorMessage } = useUi();
  const { protocolo } = useParams();
  const { updateStatus } = useDeclaracaoSaude();

  const handleOpenModal = (): void => {
    setShowModal(true);
  };

  const handleCloseModal = (): void => {
    setShowModal(false);
    onClose();
  };

  const sendPendencias = (): void => {
    showBackdrop();

    api
      .post(
        `${process.env.REACT_APP_MS_AUDITORIA_URL}/notificacao/enviar-perguntas/${protocolo}`
      )
      .then(() => {
        // updateStatus('PENDENTE DE RESPOSTA');
        successMessage('As perguntas foram enviadas ao titular com sucesso');
        handleCloseModal();
        navigate('/');
      })
      .catch(err => {
        if (err?.response?.status === 400) {
          errorMessage(err.response.data.errorMessage[0].message);
          return;
        }
        errorMessage('Não foi possível enviar as perguntas ao nosso servidor');
      })
      .finally(() => {
        hideBackdrop();
      });
  };

  return (
    <Paper sx={{ p: 2, mt: 3 }}>
      {pendencias.length === 0 && (
        <Typography>Nenhuma pergunta pendente para envio.</Typography>
      )}

      {pendencias.length > 0 && (
        <Grid container>
          {pendencias.map(pendencia => (
            <Grid
              key={JSON.stringify(pendencia)}
              item
              sm={12}
              md={12}
              sx={{
                p: 1,
                mb: 2,
                '&:last-of-type': { mb: 0 },
                '&:hover': { bgcolor: '#f9f9f9' },
              }}
            >
              <PendenciaItem
                pergunta={pendencia.pergunta}
                handleClose={onClose}
              />
            </Grid>
          ))}

          <Dialog
            fullWidth
            maxWidth="md"
            open={showModal}
            onClose={handleCloseModal}
          >
            <DialogTitle>Deseja enviar as perguntas ao titular?</DialogTitle>
            <DialogContent>
              <Typography>
                Confirme para enviar as perguntas ao e-mail do titular.
              </Typography>
            </DialogContent>

            <DialogActions>
              <Button onClick={handleCloseModal}>Cancelar</Button>
              <Button variant="contained" onClick={sendPendencias} autoFocus>
                Confirmar
              </Button>
            </DialogActions>
          </Dialog>

          <Box sx={{ marginTop: 1 }}>
            <Tooltip
              title="Envie todas as perguntas pendentes"
              placement="right"
            >
              <Button
                variant="contained"
                onClick={handleOpenModal}
                size="small"
              >
                Enviar ao titular
              </Button>
            </Tooltip>
          </Box>
        </Grid>
      )}
    </Paper>
  );
};

export default Pendencias;
