import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Paper, Tooltip, Typography } from '@mui/material';

import { useAxios } from 'hooks';
import { date } from 'utils';

type TitularRecusa = {
  aceite: boolean;
  dadosBeneficiarioId: number;
  id: number;
  justificativa: string;
  dataInclusao: string;
};

type DependenteRecusa = {
  aceite: boolean;
  dadosBeneficiarioDependenteId: number;
  id: number;
  justificativa: string;
  dataInclusao: string;
};

type RecusaPanelProps = {
  isTitular: boolean;
  idBeneficiario: string;
  protocol?: string;
};

const RecusaPanel: React.FC<RecusaPanelProps> = ({
  isTitular,
  idBeneficiario,
  protocol,
}) => {
  const { api } = useAxios();
  const { protocolo } = useParams();

  const [titularRecusa, setTitularRecusa] = useState<TitularRecusa[]>([]);
  const [dependenteRecusa, setDependenteRecusa] = useState<DependenteRecusa[]>(
    []
  );

  const hasRecusaTitular = useMemo(
    () => titularRecusa.filter(recusa => !recusa.aceite).length > 0,
    [titularRecusa]
  );

  const hasRecusaDependente = useMemo(
    () => dependenteRecusa.filter(recusa => !recusa.aceite).length > 0,
    [dependenteRecusa]
  );

  const fetchRecusaTitular = useCallback(() => {
    const url = `${
      process.env.REACT_APP_MS_AUDITORIA_URL
    }/aceite-auditoria/titular/${protocol ?? protocolo}`;
    const params = { idTitular: idBeneficiario };

    api.get(url, { params }).then(({ data }) => {
      setTitularRecusa(data);
    });
  }, [api, idBeneficiario, protocolo]);

  const fetchRecusaDependente = useCallback(() => {
    const url = `${
      process.env.REACT_APP_MS_AUDITORIA_URL
    }/aceite-auditoria/dependente/${protocol ?? protocolo}`;
    const params = { idDependente: idBeneficiario };

    api.get(url, { params }).then(({ data }) => {
      setDependenteRecusa(data);
    });
  }, [api, idBeneficiario, protocolo]);

  useEffect(() => {
    if (isTitular) {
      fetchRecusaTitular();
    } else {
      fetchRecusaDependente();
    }
  }, [fetchRecusaDependente, fetchRecusaTitular, isTitular]);

  if (!hasRecusaTitular && !hasRecusaDependente) {
    return null;
  }

  return (
    <Paper sx={{ p: 2 }}>
      {hasRecusaTitular && (
        <Box>
          <Typography fontWeight={600}>
            Motivos do titular ao recusar o laudo médico:
          </Typography>

          {titularRecusa.map(({ id, justificativa, dataInclusao }) => (
            <Box key={id} sx={{ p: '10px 0' }}>
              <Typography component="span">{justificativa}</Typography>
              <br />
              <Typography fontSize={12} color="#969696">
                {`Enviado em ${date.getDatetimeFromStringISO(dataInclusao)}`}
              </Typography>
            </Box>
          ))}
        </Box>
      )}

      {hasRecusaDependente && (
        <Box>
          <Typography fontWeight={600}>
            Motivos do titular ao recusar o laudo médico:
          </Typography>

          {dependenteRecusa.map(({ dataInclusao, id, justificativa }) => (
            <Tooltip
              key={id}
              placement="left"
              title={`Enviado em ${date.getDatetimeFromStringISO(
                dataInclusao
              )}`}
              sx={{ mb: 1 }}
            >
              <Typography>{justificativa}</Typography>
            </Tooltip>
          ))}
        </Box>
      )}
    </Paper>
  );
};

export default RecusaPanel;
