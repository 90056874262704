import { useMemo } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { api } from 'services';
import { token as storage } from 'utils';
import { useUi } from 'hooks';

type FormValues = {
  justificativa: string;
};

type DeclineModalProps = {
  open: boolean;
  handleClose: () => void;
  idTitular?: string;
  idDependentes: string[];
};

const MIN_LENGTH_INPUT_TEXT = 10;
const MAX_LENGTH_INPUT_TEXT = 300;

const schema = yup.object().shape({
  justificativa: yup
    .string()
    .min(
      MIN_LENGTH_INPUT_TEXT,
      `O tamanho mínimo é de ${MIN_LENGTH_INPUT_TEXT} caracteres`,
    )
    .max(
      MAX_LENGTH_INPUT_TEXT,
      `O tamanho máximo é de ${MAX_LENGTH_INPUT_TEXT} caracteres`,
    )
    .required(),
});

const DeclineModal: React.FC<DeclineModalProps> = ({
  open,
  handleClose,
  idTitular,
  idDependentes,
}) => {
  const { hideBackdrop, showBackdrop, errorMessage } = useUi();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { control, handleSubmit, formState, reset } = useForm<FormValues>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      justificativa: '',
    },
  });

  const protocolo = useMemo(
    () => searchParams.get('protocolo'),
    [searchParams],
  );

  const onSubmit: SubmitHandler<FormValues> = ({ justificativa }) => {
    const url = `${process.env.REACT_APP_MS_AUDITORIA_URL}/aceite-auditoria/${protocolo}`;
    const headers = { Authorization: `Bearer ${storage.getAccessToken()}` };

    const promises = idDependentes.map(idBeneficiario => {
      const body = { aceite: false, justificativa, idBeneficiario };

      return api.post(`${url}/dependente`, body, { headers });
    });

    if (idTitular) {
      const body = { aceite: false, justificativa, idBeneficiario: idTitular };
      promises.push(api.post(`${url}/titular`, body, { headers }));
    }

    showBackdrop();

    return Promise.all(promises)
      .then(() => {
        navigate('/registrada');
      })
      .catch(err => {
        if (err?.response.status === 400) {
          errorMessage(err.response.data.errorMessage[0].message);
        }
        navigate('/rejeitada');
      })
      .finally(() => {
        hideBackdrop();
      });
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Recusar análise de declaração de saúde?</DialogTitle>
        <DialogContent>
          <Box sx={{ padding: '10px 0' }}>
            <Controller
              control={control}
              name="justificativa"
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  multiline
                  variant="standard"
                  inputProps={{ maxLength: MAX_LENGTH_INPUT_TEXT }}
                  label="Justifique o motivo da recusa"
                  error={!!formState.errors?.justificativa}
                  helperText={`${field.value.length}/${MAX_LENGTH_INPUT_TEXT}`}
                />
              )}
            />
          </Box>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => {
              reset();
              handleClose();
            }}
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            variant="contained"
            disabled={!formState.isValid || formState.isSubmitting}
          >
            {formState.isSubmitting ? 'Enviando...' : 'Enviar'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DeclineModal;
